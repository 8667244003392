import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Chevron, Container } from './styles';
import type { Props } from './types';

const BackLinkV2: FC<Props> = ({ size = 8.5 }) => {
  const navigate = useNavigate();

  return (
    <Container onClick={() => navigate(-1)}>
      <Chevron size={size} />
    </Container>
  );
};

export default memo(BackLinkV2);
