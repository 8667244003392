import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import useEmployee from 'apollo/hooks/employee/useEmployee';
import useEmployeeActions from 'apollo/hooks/employee/useEmployeeActions';
import usePermissionsGroups from 'apollo/hooks/permissionsGroup/usePermissionsGroups';
import getPartner from 'apollo/requests/getPartner';
import { UserRole } from 'apollo/graphql.types';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import type { EmployeeFormFields } from 'model/Employee';

const useConnect = () => {
  const { employeeId } = useParams<{ employeeId: string }>();
  const { employee, loading: getEmployeeLoading } = useEmployee({
    id: employeeId,
  });
  const { permissionsGroups, getPermissionsGroupsLoading } =
    usePermissionsGroups();
  const {
    deleteEmployee,
    updateEmployee,
    updateEmployeeAvatar,
    toggleEmployeeActivation,
    loading: actionsEmployeeLoading,
  } = useEmployeeActions();
  const {
    openDialog,
    close,
    openPartnerDetails,
    openEmployeeAdvancedMenu,
    openUpdatePasswordForm,
  } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [selectedSection, setSelectedSection] = useState('profile');

  const initialProfileFormValues = useMemo(
    () => ({
      avatarImageId: employee?.avatarImageId || '',
      avatarImageIsUploading: false,
      avatarImageUrl: employee?.avatarImageUrl || '',
      permissionsGroupId: employee?.permissions?.id || '',
      avatarImageSize: employee?.avatarImageSize || 0,
      document: employee?.document || '',
      email: employee?.email || '',
      id: employee?.id || '',
      firstName: employee?.firstName || '',
      lastName: employee?.lastName || '',
      phoneNumber: employee?.phoneNumber || '',
    }),
    [employee],
  );

  const permissionsGroupsOptions = useMemo(() => {
    const permissionsOptions = permissionsGroups.map((permissionsGroup) => ({
      value: permissionsGroup.id,
      label: permissionsGroup.name,
    }));

    return [{ value: 'admin', label: 'Admin' }, ...permissionsOptions];
  }, [permissionsGroups]);

  const handleSelectedSection = useCallback((section: string) => {
    setSelectedSection(section);
  }, []);

  const handleSubmit = useCallback(
    async (values: EmployeeFormFields) => {
      try {
        if (employeeId) {
          await updateEmployee({ id: employeeId, data: values });
          enqueueSnackbar('El empleado se ha actualizado correctamente', {
            variant: 'success',
          });
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('employee', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [enqueueSnackbar, employeeId, updateEmployee],
  );

  const removeEmployee = useCallback(async () => {
    try {
      if (employeeId) {
        await deleteEmployee(employeeId);
        navigate('/employees', { replace: true });
        close();
        enqueueSnackbar('El empleado se ha eliminado correctamente', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(formatErrors('employee', e.message, 'eliminar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [close, deleteEmployee, employeeId, enqueueSnackbar, navigate]);

  const handleOpenRemoveDialog = useCallback(() => {
    openDialog({
      acceptButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      description:
        'Vas a eliminar a este empleado, se borrarán todos sus datos y es una acción que no se puede deshacer, ¿quieres eliminarlo?',
      onAccept: removeEmployee,
      title: 'Eliminar usuario',
      variant: 'danger',
    });
  }, [removeEmployee, openDialog]);

  const handleToggleEmployeeActivation = useCallback(async () => {
    try {
      if (employee?.id) {
        await toggleEmployeeActivation(employee.id);
        close();
        enqueueSnackbar(
          `El empleado se ha ${
            employee.isActive ? 'desactivado' : 'activado'
          } correctamente`,
          {
            variant: 'success',
          },
        );
      }
    } catch (e) {
      enqueueSnackbar(formatErrors('employee', e.message, 'actualizar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [employee, toggleEmployeeActivation, close, enqueueSnackbar]);

  const handleSubmitAvatar = useCallback(
    async (imageId: string) => {
      try {
        if (employeeId) {
          await updateEmployeeAvatar({
            id: employeeId,
            imageId,
          });
          enqueueSnackbar('Se ha actualizado la imagen correctamente', {
            variant: 'success',
          });
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('employee', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [employeeId, enqueueSnackbar, updateEmployeeAvatar],
  );

  const handleOpenPartnerDetailsModal = useCallback(async () => {
    if (employee?.partnerNum) {
      const hostPartner = await getPartner(employee.partnerNum);
      openPartnerDetails({ partner: hostPartner });
    }
  }, [employee, openPartnerDetails]);

  const handleOpenAdvancedEmployeeMenuModal = useCallback(async () => {
    openEmployeeAdvancedMenu({
      employeeActive: employee?.isActive || false,
      toggleEmployeeActivationStatus: handleToggleEmployeeActivation,
      removeEmployee: handleOpenRemoveDialog,
      updatePassword: () =>
        openUpdatePasswordForm({
          employeeId: employee?.id,
        }),
    });
  }, [
    employee,
    handleToggleEmployeeActivation,
    handleOpenRemoveDialog,
    openEmployeeAdvancedMenu,
    openUpdatePasswordForm,
  ]);

  return {
    employeeId,
    handleOpenAdvancedEmployeeMenuModal,
    handleOpenPartnerDetailsModal,
    handleSelectedSection,
    handleSubmit,
    handleSubmitAvatar,
    initialProfileFormValues,
    isLoading:
      getEmployeeLoading ||
      actionsEmployeeLoading ||
      getPermissionsGroupsLoading,
    partnerNum: employee?.partnerNum,
    selectedSection,
    workSchedules: employee?.workSchedules || [],
    permissionsGroupsOptions,
    logins: employee?.logins || [],
    isAdmin: employee?.role === UserRole.Admin,
    isActive: employee?.isActive || false,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
