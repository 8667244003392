import type { FC } from 'react';
import Switch from 'components/Inputs/CategorySwitch';
import { getDiscountTypeName } from 'model/Discount';
import Placeholder from './Placeholder';
import useConnect from './connect';
import {
  AcceptButton,
  Buttons,
  CancelButton,
  CategoriesList,
  ChevronIcon,
  Container,
  Discount,
  DiscountContent,
  DiscountName,
  DiscountsContainer,
  DiscountValue,
  EmptyState,
  Header,
  Space,
  Title,
} from './styles';

const AssignDiscounts: FC = () => {
  const {
    onClose,
    handleSelect,
    handleSubmit,
    discounts,
    isOpen,
    isLoading,
    isSelected,
    oneSelection,
  } = useConnect();

  const hasResults = discounts?.length > 0;

  return (
    <Container disableBackdropClick open={isOpen} onClose={onClose}>
      <Header>
        <Title>Asignar descuentos</Title>
      </Header>

      {isLoading && <Placeholder nRows={7} />}

      {!isLoading && !hasResults && (
        <EmptyState title="No hay descuentos" iconName="discount">
          Aún no has creado ningún descuento, crea al menos una para mostrar la
          información desde la configuración de la organización
        </EmptyState>
      )}

      {!isLoading && hasResults && (
        <DiscountsContainer>
          <CategoriesList>
            <Space />
            <Space />
            {discounts &&
              discounts.map((discount) =>
                oneSelection ? (
                  <Discount
                    $isSelected={isSelected(discount)}
                    key={`oneSelect_${discount.id}`}
                    onClick={() => handleSelect(discount)}
                  >
                    <DiscountName> {discount.name}</DiscountName>
                    <DiscountContent>
                      <DiscountValue>
                        {discount.price
                          ? `${discount.price} cr`
                          : `${discount.percentage} %`}
                      </DiscountValue>
                      <ChevronIcon />
                    </DiscountContent>
                  </Discount>
                ) : (
                  <Switch
                    key={`multiSelect_${discount.id}`}
                    id={discount.id}
                    label={`${discount.name} (${getDiscountTypeName(
                      discount.type,
                    )}) - ${
                      discount.price
                        ? `${discount.price} cr`
                        : `${discount.percentage} %`
                    }`}
                    onSelect={() => handleSelect(discount)}
                    isChecked={isSelected(discount)}
                  />
                ),
              )}
            <Space />
            <Space />
            <Space />
          </CategoriesList>
        </DiscountsContainer>
      )}

      <Buttons>
        <CancelButton onClick={onClose}>Cerrar</CancelButton>
        <AcceptButton onClick={handleSubmit}>Seleccionar</AcceptButton>
      </Buttons>
    </Container>
  );
};

export default AssignDiscounts;
