import { useCallback, useMemo, useState } from 'react';
import { FormikConfig } from 'formik';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useProductActions from 'apollo/hooks/product/useProductActions';
import { QuantityMovementType } from 'apollo/graphql.types';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import type { ProductAdjustQuantityFormFields } from 'model/Product';

const useConnect = () => {
  const [isFirstSave, setIsFirstSave] = useState(true);
  const [adjustState, setAdjustState] = useState<{
    isNegative: boolean;
    remainingQuantity: number;
    show: boolean;
  } | null>({
    isNegative: false,
    remainingQuantity: 0.0,
    show: false,
  });
  const { adjustProductQuantity, loading } = useProductActions();
  const { close, type, adjustProductQuantityPayload } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const {
    product,
    reloadPage,
    actionType = 'set',
  } = adjustProductQuantityPayload || {};

  const initialValues = useMemo<ProductAdjustQuantityFormFields>(
    () => ({
      baseWeight: product?.baseWeight
        ? Number(product.baseWeight.toFixed(2))
        : 0.0,
      name: product?.name || '',
      quantity: product?.quantity ? Number(product.quantity.toFixed(2)) : 0.0,
      newQuantity: '' as unknown as number,
      note: '',
      movementType: QuantityMovementType.Stock,
    }),
    [product],
  );

  const handleClose = useCallback(() => {
    setAdjustState(null);
    setIsFirstSave(true);
    close();
  }, [close]);

  const handleSubmit = useCallback<
    FormikConfig<ProductAdjustQuantityFormFields>['onSubmit']
  >(
    async (data) => {
      const currentTotalQuantity = Number(data?.quantity || 0);
      const newQuantity = Number(data?.newQuantity || 0);
      let newTotalQuantity = currentTotalQuantity;
      let remainingQuantity = 0;

      if (actionType === 'set') {
        newTotalQuantity = newQuantity - Number(data?.baseWeight || 0);
        remainingQuantity = newTotalQuantity - currentTotalQuantity;
      } else if (actionType === 'add') {
        newTotalQuantity = currentTotalQuantity + newQuantity;
      } else if (actionType === 'remove') {
        newTotalQuantity = currentTotalQuantity - newQuantity;
        remainingQuantity = newTotalQuantity;
      }

      try {
        if (isFirstSave && actionType !== 'add') {
          setAdjustState({
            show: true,
            isNegative: remainingQuantity < 0,
            remainingQuantity: Number(remainingQuantity.toFixed(2)),
          });
          setIsFirstSave(false);
        } else if (product?.id) {
          await adjustProductQuantity(product.id, {
            ...data,
            newQuantity: newTotalQuantity,
          });
          enqueueSnackbar(`El producto ha sido ajustado correctamente`, {
            variant: 'success',
          });
          if (reloadPage) {
            window.location.reload();
          }
          handleClose();
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('product', e.message, 'ajustar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [
      actionType,
      isFirstSave,
      product,
      adjustProductQuantity,
      enqueueSnackbar,
      reloadPage,
      handleClose,
    ],
  );

  const handleChangeNewQuantity = useCallback(async () => {
    setIsFirstSave(true);
  }, []);

  return {
    actionType,
    adjustState,
    handleClose,
    handleSubmit,
    initialValues,
    isFirstSave,
    handleChangeNewQuantity,
    isLoading: loading,
    isOpen: type === ModalType.ADJUST_PRODUCT_QUANTITY,
  };
};

export default useConnect;
