type ErrorScope =
  | 'category'
  | 'discount'
  | 'employee'
  | 'expense'
  | 'extraQuantity'
  | 'organization'
  | 'partner'
  | 'temporaryPartner'
  | 'product'
  | 'provider'
  | 'purchase'
  | 'quota'
  | 'note'
  | 'transaction'
  | 'file'
  | 'event'
  | 'permissions'
  | 'document'
  | 'cashier'
  | 'menu';

type ErrorAction = 'crear' | 'actualizar' | 'eliminar' | 'cancelar' | 'ajustar';

export const formatErrors = (
  scope: ErrorScope,
  value: string,
  action: ErrorAction,
): string => {
  if (value === 'You are not have permissions in the system.') {
    return 'No tienes permisos para realizar esta acción';
  }

  if (scope === 'product') {
    switch (value) {
      case 'A product with this name already exists':
        return 'Existe un producto con este nombre.';
      case 'A product with this name and reference already exists':
        return 'Existe un producto con este nombre y referencia.';
      case 'A product with this barcode already exists':
        return 'Existe un producto con este código de barra.';
      case 'Negative quantities are not allowed':
        return 'No se permiten cantidades negativas.';
      case 'The maximum number of products allowed in the subscription has been reached.':
        return 'Se ha alcanzado el máximo de productos.';
      default:
        return `Ha ocurrido algún problema al ${action} el producto.`;
    }
  }

  if (scope === 'menu') {
    switch (value) {
      case 'Exists menu whit same name':
        return 'Existe un menú con este nombre.';
      default:
        return `Ha ocurrido algún problema al ${action} el menú.`;
    }
  }

  if (scope === 'document') {
    switch (value) {
      default:
        return `Ha ocurrido algún problema al ${action} el documento.`;
    }
  }

  if (scope === 'cashier') {
    switch (value) {
      case 'There is an open cashier.':
        return 'Ya existe una caja abierta.';
      default:
        return `Ha ocurrido algún problema al ${action} la caja.`;
    }
  }

  if (scope === 'file') {
    switch (value) {
      case 'The maximum number of partners files allowed in the subscription has been reached.':
        return 'Ha llegado al numero máximo de archivos.';
      case 'Required drive login.':
        return 'Debes crear la integración con drive.';
      case 'File not found in S3':
        return 'No se ha encontrado el archivo en el servidor.';
      default:
        return `Ha ocurrido algún problema al ${action} el archivo.`;
    }
  }

  if (scope === 'permissions') {
    switch (value) {
      default:
        return `Ha ocurrido algún problema al ${action} el grupo de permisos.`;
    }
  }

  if (scope === 'event') {
    switch (value) {
      case 'Start date must be before end date.':
        return 'La fecha inicio debe ser previa al fin.';
      default:
        return `Ha ocurrido algún problema al ${action} el evento.`;
    }
  }

  if (scope === 'provider') {
    switch (value) {
      case 'Exists provider with this name.':
        return 'Existe un proveedor con este nombre.';
      default:
        return `Ha ocurrido algún problema al ${action} el proveedor.`;
    }
  }

  if (scope === 'expense') {
    switch (value) {
      default:
        return `Ha ocurrido algún problema al ${action} el gasto.`;
    }
  }

  if (scope === 'category') {
    switch (value) {
      case 'The category with this name exists.':
        return 'Existe una categoría con este nombre.';
      default:
        return `Ha ocurrido algún problema al ${action} la categoría.`;
    }
  }

  if (scope === 'extraQuantity') {
    switch (value) {
      case 'A extra quantity with this name already exists.':
        return 'Existe una cantidad extra con este nombre.';
      default:
        return `Ha ocurrido algún problema al ${action} la cantidad extra.`;
    }
  }

  if (scope === 'employee') {
    switch (value) {
      case 'Exists employee with this email address.':
        return 'Existe un empleado con este email o documento.';
      case "The organisation's administrator may not be removed.":
        return 'No se puede eliminar el empleado, contacte con soporte.';
      case 'The maximum number of employees allowed in the subscription has been reached.':
        return 'Se ha alcanzado el máximo de empleados.';
      case 'The partner has employee.':
        return 'Este socio ya es empleado.';
      case 'There is a user with this document but uses a different email address, please use the same email address.':
        return 'Existe un usuario con este documento pero usa un email distinto, por favor usa el mismo email.';
      default:
        return `Ha ocurrido algún problema al ${action} el empleado.`;
    }
  }

  if (scope === 'purchase') {
    switch (value) {
      case 'Not enough credits to complete the purchase':
        return 'No hay suficientes créditos para completar la compra.';
      case 'Negative quantities are not allowed':
        return 'No se dispone de la cantidad de uno de los productos.';
      case 'The partner has exceeded the maximum consumption':
        return 'El socio excede el consumo máximo.';
      case 'Product exceded extra quantity':
        return 'Valor extra del producto superior al permitido.';
      default:
        return `Ha ocurrido algún problema al ${action} la compra.`;
    }
  }

  if (scope === 'note') {
    switch (value) {
      default:
        return `Ha ocurrido algún problema al ${action} la nota.`;
    }
  }

  if (scope === 'temporaryPartner') {
    switch (value) {
      case 'Max temporary partner by organization.':
        return 'Se ha alcanzado el máximo de solicitudes.';
      case 'The email or document is in use by another partner.':
        return 'Los datos de la solicitud ya están en uso.';
      case 'The RFID code is in use by another partner.':
        return 'El código RFID esta en uso.';
      case 'The email is in use by another partner.':
        return 'El email esta en uso.';
      case 'The document is in use by another partner.':
        return 'El documento esta en uso.';
      default:
        return `Ha ocurrido algún problema al ${action} la solicitud.`;
    }
  }

  if (scope === 'partner') {
    switch (value) {
      case 'Partner host member not found':
        return 'No se encuentra el socio avalador';
      case "The organisation's administrator may not be removed.":
        return 'No se puede eliminar el socio, contacte con soporte.';
      case 'The maximum number of partners allowed in the subscription has been reached.':
        return 'Se ha alcanzado el máximo de socios.';
      case 'The email or document is in use by another partner.':
        return 'El email o documento están en uso';
      case 'The document is in use by another partner.':
        return 'Este documento esta en uso';
      case 'Not enough credits to assign quota.':
        return 'No hay suficientes créditos para asignar la cuota.';
      case 'Product discount is not valid.':
        return 'El descuento aplicado a la cuota no es valido.';
      case 'The partner has exceeded the maximum consumption':
        return 'El socio excede el consumo máximo';
      case 'You cannot add credits to your partner':
        return 'No puedes añadir créditos a tu socio';
      case 'You cannot update your partner':
        return 'No puedes editar tu socio';
      case 'A user with that identifier exists.':
        return 'Existe un socio con ese identificador';
      case 'You must use a correct partner identifier.':
        return 'Debes asignar un identificador correcto';
      case 'The RFID code is in use by another partner.':
        return 'El código RFID esta en uso';
      default:
        return `Ha ocurrido algún problema al ${action} el socio.`;
    }
  }

  if (scope === 'quota') {
    switch (value) {
      default:
        return `Ha ocurrido algún problema al ${action} la quota.`;
    }
  }

  if (scope === 'discount') {
    switch (value) {
      default:
        return `Ha ocurrido algún problema al ${action} el descuento.`;
    }
  }

  if (scope === 'organization') {
    switch (value) {
      case 'Ups! A problem occurred when we set organization config.':
        return 'No se ha podido actualizar la configuración.';
      case 'Organization pin dont match.':
        return 'Pin de organización erróneo.';
      default:
        return `Ha ocurrido algún problema al ${action} la organización.`;
    }
  }

  if (scope === 'transaction') {
    switch (value) {
      default:
        return `Ha ocurrido algún problema al ${action} la transacción.`;
    }
  }

  return 'Ha ocurrido algún problema, contacte con soporte.';
};

export const isClosedCashierError = (error: string) =>
  error === 'You must open cashier';
