import useEmployees from 'apollo/hooks/employee/useEmployees';
import useModal from 'apollo/hooks/useModal';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useCallback, useState } from 'react';
import getPartner from 'apollo/requests/getPartner';
import type { Partner } from 'model/Partner';

const useConnect = () => {
  const { employees, loading, refetch } = useEmployees();
  const { openPartnerDetails } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [isRefetching, setIsRefetching] = useState(false);
  const [filter, setFilter] = useState('');
  const fromTabletLandscape = useMediaQuery(from.tabletLandscape.query);

  const handleSearch = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value;

      setIsRefetching(true);
      try {
        await refetch({ filter: newSearch });
        setFilter(newSearch);
        setIsRefetching(false);
      } catch (error) {
        setIsRefetching(false);
        enqueueSnackbar('Unable to load search', { variant: 'error' });
      }
    },
    [enqueueSnackbar, refetch],
  );

  const handleOpenPartnerDetailsModal = useCallback(
    async (partnerNum: Partner['memberNum']) => {
      if (partnerNum) {
        const hostPartner = await getPartner(partnerNum);
        openPartnerDetails({ partner: hostPartner });
      }
    },
    [openPartnerDetails],
  );

  return {
    employees,
    filter,
    fromTabletLandscape,
    handleOpenPartnerDetailsModal,
    handleSearch,
    isLoading: loading,
    isRefetching,
  };
};

export default useConnect;
