import type { FC } from 'react';
import Header from 'containers/Common/Header';
import Loader from 'components/Loader';
import Row from './Row';
import TableHead from './TableHead';
import useConnect from './connect';
import { Container, Main, EmptyState, Filters } from './styles';

const Employees: FC = () => {
  const {
    employees,
    isLoading,
    handleSearch,
    fromTabletLandscape,
    filter,
    isRefetching,
    handleOpenPartnerDetailsModal,
  } = useConnect();

  const hasResults = employees?.length > 0;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header title="Empleados">
        <Filters
          isLoading={isRefetching}
          onSearchChange={handleSearch}
          filter={filter}
          nResults={employees?.length || 0}
        />
      </Header>
      <Main>
        {!hasResults && (
          <EmptyState
            title="No hay empleados"
            iconName="employees"
            // buttonLabel="Crear empleado"
            // onClickButton={openNewEmployeeModal}
          >
            Aún no has creado ningún empleado, crea al menos uno para mostrar la
            información
          </EmptyState>
        )}

        {hasResults && (
          <>
            {fromTabletLandscape && <TableHead />}
            {employees.map((employee) => (
              <Row
                employee={employee}
                fromTabletLandscape={fromTabletLandscape}
                key={employee.id}
                openPartnerDetails={handleOpenPartnerDetailsModal}
              />
            ))}
          </>
        )}
      </Main>
    </Container>
  );
};

export default Employees;
