import { FC, memo } from 'react';
import Price from 'components/Price';
import { formatDate } from 'utils/dates/formatDates';
import {
  Container,
  Credits,
  Date,
  Employee,
  EmployeeContent,
  ExtraQuantity,
  Partner,
  Quantity,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ className, transaction, countToMaxConsume }) => {
  const { employee, partner, quantity, extraQuantity, total, date } =
    transaction;

  return (
    <Container className={className}>
      <EmployeeContent>
        <Employee to={`/employees/${employee.id}`}>
          {employee.fullName}
        </Employee>
      </EmployeeContent>
      <Partner>
        <Employee to={`/partners/${partner.id}`}>{partner.fullName}</Employee>
      </Partner>
      <Date>{formatDate(date)}</Date>
      <Quantity>{quantity.toFixed(2)}</Quantity>
      {countToMaxConsume && (
        <ExtraQuantity>{extraQuantity.toFixed(2)}</ExtraQuantity>
      )}
      <Credits>
        <Price>{Number(total.toFixed(2))}</Price>
      </Credits>
    </Container>
  );
};

export default memo(Row);
