import { makeVar } from '@apollo/client';
import type { ProductOrderItem } from 'model/Product';
import type { Partner } from 'model/Partner';
import type { Discount } from 'model/Discount';

export type OrderVarData = {
  products: ProductOrderItem[];
  partner: Partner | null;
  orderDiscount: Partial<Discount> | null;
  productDiscount: Partial<Discount> | null;
};

const emptyOrderState: OrderVarData = {
  products: [],
  partner: null,
  orderDiscount: null,
  productDiscount: null,
};

export const orderVar = makeVar<OrderVarData>(emptyOrderState);

export const resetOrderVar = () => {
  orderVar(emptyOrderState);
};

export const updatePartnerOrderVar = (partner: Partner | null) => {
  orderVar({ ...orderVar(), partner });
};

export const updateOrderDiscountOrderVar = (
  orderDiscount: Partial<Discount> | null,
) => {
  orderVar({ ...orderVar(), orderDiscount });
};

export const updateProductDiscountOrderVar = (
  productDiscount: Partial<Discount> | null,
) => {
  orderVar({ ...orderVar(), productDiscount });
};

export const updateProductsOrderVar = (products: ProductOrderItem[]) => {
  orderVar({ ...orderVar(), products });
};
