import { gql } from '@apollo/client';
import {
  EMPLOYEE_FRAGMENT,
  EMPLOYEE_WORK_SCHEDULE_FRAGMENT,
} from 'apollo/fragments/Employee';

export const UPDATE_EMPLOYEE = gql`
  mutation UpdateEmployee($id: ID!, $data: UpdateEmployeeDto!) {
    updateEmployee(id: $id, data: $data) {
      ...EmployeeData
    }
  }
  ${EMPLOYEE_FRAGMENT}
`;

export const TOGGLE_EMPLOYEE_ACTIVATION = gql`
  mutation ToggleEmployeeActivation($id: ID!) {
    toggleEmployeeActivation(id: $id) {
      ...EmployeeData
    }
  }
  ${EMPLOYEE_FRAGMENT}
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($id: ID!, $data: UpdatePasswordDto!) {
    updatePassword(id: $id, data: $data)
  }
`;

export const REMOVE_EMPLOYEE = gql`
  mutation RemoveEmployee($id: ID!) {
    removeEmployee(id: $id)
  }
`;

export const CONVERT_PARTNER_TO_EMPLOYEE = gql`
  mutation ConvertToEmployee($partnerId: ID!) {
    convertToEmployee(partnerId: $partnerId) {
      id
    }
  }
`;

export const ADD_SCHEDULE_TO_EMPLOYEE = gql`
  mutation AddScheduleToEmployee($employeeId: ID!, $data: WorkScheduleDto!) {
    addScheduleToEmployee(employeeId: $employeeId, data: $data) {
      ...WorkScheduleData
    }
  }
  ${EMPLOYEE_WORK_SCHEDULE_FRAGMENT}
`;

export const REMOVE_SCHEDULE_EMPLOYEE = gql`
  mutation RemoveScheduleEmployee($scheduleId: ID!) {
    removeScheduleToEmployee(scheduleId: $scheduleId)
  }
`;
