import styled, { css, keyframes } from 'styled-components';
import { from } from 'styles/responsive';
import DefaultDoneIcon from 'components/Icon/Icons/Done';
import DefaultPrice from 'components/Price';

export const Vibrate = keyframes`
  0% { transform: translate(-1px); }
  25% { transform: translate(1px); }
  50% { transform: translate(-1px); }
  75% { transform: translate(1px); }
  100% { transform: translate(-1px); }
`;

export const Thumbnail = styled.div`
  align-items: center;
  display: flex;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  height: 3.5rem;
  justify-content: center;
  margin-right: 1.2rem;
  width: 3.5rem;
`;

export const Container = styled.a<{
  $isSelected: boolean;
  $isEditingMode?: boolean;
  $isReverse?: boolean;
}>`
  align-items: center;
  border: 1px solid
    ${({ $isEditingMode, theme }) =>
      !$isEditingMode ? 'rgba(245, 245, 245, 1)' : theme.colors.primary};
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-flow: row;
  height: 8rem;
  width: 100%;
  padding-left: 1.5rem;

  ${from.tabletPortrait`
    width: 18rem;
  `};

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey500}0a;
  }

  ${({ $isEditingMode, $isReverse }) =>
    $isEditingMode &&
    css`
      animation: ${Vibrate} 1.2s infinite;
      animation-direction: ${$isReverse ? 'reverse' : 'normal'};
    `}

  ${({ $isSelected, theme }) => css`
    ${theme.colors.products
      .map((color, index) => {
        return `
          :nth-child(${index + 1}n) {
            ${
              $isSelected
                ? `
                background-color: ${color}14;
                
                &:hover {
                  background-color: ${color}28;
                }
              `
                : ''
            }

            ${Thumbnail} {
              ${$isSelected ? '' : `background-color: ${color}14;`}
              color: ${color};
            }
          }
        `;
      })
      .join('\n')}
  `};
`;

export const InfoProduct = styled.div`
  display: flex;
  flex-flow: column;
`;

export const PriceContent = styled.div`
  align-items: flex-end;
  display: flex;
  margin-top: 0.25rem;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  flex: 1;
  text-transform: capitalize;
`;

export const ReferenceCode = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-weight: 600;
  font-size: 0.8rem;
  margin-top: 0.25rem;
`;

export const OriginalPrice = styled.span`
  color: ${({ theme }) => theme.colors.grey900};
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-left: 0.5rem;
  position: relative;
  font-size: 0.8rem;

  &:before {
    border-color: inherit;
    border-top: 1px solid;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 45%;
    transform: skewY(-10deg);
  }
`;

export const Price = styled(DefaultPrice)`
  color: ${({ theme }) => theme.colors.grey900};
`;

export const DoneIcon = styled(DefaultDoneIcon)`
  width: 50%;
`;

export const ImageContainer = styled.div`
  max-width: 4.5rem;
  margin-right: 0.8rem;
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
