import { gql } from '@apollo/client';
import { IMAGE_FRAGMENT } from './Image';
import { PURCHASE_FRAGMENT } from './Purchase';
import {
  PARTNER_CREDITS_TRANSACTION_FRAGMENT,
  PARTNER_QUOTA_TRANSACTION_FRAGMENT,
} from './Partner';

export const ORGANIZATION_CONFIG_FRAGMENT = gql`
  fragment OrganizationConfigData on OrganizationConfig {
    allowExtraQuantity
    allowNegativeCredits
    activateRfidReader
    activateProductReference
    forceOpenCashier
    activeStrictCashier
    contractText
    creditConcepts
    daysToRemoveExpiredPartner
    deleteExpiredPartner
    id
    productsListType
    defaultProductsOrder
    reasignReleasedPartnerNumber
    updatedAt
    maxExtraQuantity
  }
`;

export const MENU_SCHEDULE_FRAGMENT = gql`
  fragment MenuScheduleData on MenuSchedule {
    id
    dayOfWeek
    schedules {
      startTime
      endTime
    }
  }
`;

export const ORGANIZATION_MENU_CONFIG_FRAGMENT = gql`
  fragment OrganizationMenuConfigData on OrganizationMenuConfig {
    id
    name
    type
    showLogo
    useLogoToBackground
    showProductPrice
    showProductDescription
    showProductDiscount
    showPriceSimbol
    defaultImageUrl
    backgroundColor
    imageBorderColor
    productTitleColor
    productDescriptionColor
    productPriceColor
    categoriesColor
    categoriesNames
    menuCode
    menuActive
    updatedAt
    createdAt
    menuSchedules {
      ...MenuScheduleData
    }
  }
  ${MENU_SCHEDULE_FRAGMENT}
`;

export const ORGANIZATION_MENU_DATA_FRAGMENT = gql`
  fragment OrganizationMenuDataData on OrganizationMenuData {
    type
    showLogo
    useLogoToBackground
    showProductPrice
    showProductDescription
    showProductDiscount
    showPriceSimbol
    defaultImageUrl
    backgroundColor
    imageBorderColor
    productTitleColor
    productDescriptionColor
    productPriceColor
    categoriesColor
    categoriesNames
    menuCode
    menuActive
    organizationLogo
    products {
      categories
      price
      priceWithDiscount
      hasDiscount
      description
      title
      imageUrl
      referenceCode
    }
  }
`;

export const ORGANIZATION_SUBSCRIPTION_FRAGMENT = gql`
  fragment OrganizationSubscriptionData on OrganizationSubscription {
    id
    allowedEmployees
    allowedDiscounts
    allowedCashier
    allowedExpenses
    allowedGenerateFiles
    allowedMetrics
    allowedOrders
    allowedPartnerTransactions
    allowedProductTransactions
    allowedReports
    description
    maxEmployees
    maxPartners
    maxProducts
    maxUserFiles
    name
    price
  }
`;

export const ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationData on Organization {
    document
    name
    id
    isActive
    state
    email
    zipCode
    locality
    city
    address
    hasDriveIntegration
    logo {
      ...ImageData
    }
    config {
      ...OrganizationConfigData
    }
    subscription {
      ...OrganizationSubscriptionData
    }
  }
  ${ORGANIZATION_CONFIG_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${ORGANIZATION_SUBSCRIPTION_FRAGMENT}
`;

export const ORGANIZATION_DOCUMENT_FRAGMENT = gql`
  fragment OrganizationDocumentData on OrganizationDocument {
    name
    id
    text
    hasSignature
    customData {
      key
      label
      inputType
    }
    createdAt
    updatedAt
  }
`;

export const ORGANIZATION_TRANSACTIONS_FRAGMENT = gql`
  fragment OrganizationTransactionsData on OrganizationTransactionsData {
    purchases {
      ...PurchaseData
    }
    credits {
      ...PartnerCreditTransactionData
    }
    quotas {
      ...PartnerQuotaTransactionData
    }
  }
  ${PURCHASE_FRAGMENT}
  ${PARTNER_CREDITS_TRANSACTION_FRAGMENT}
  ${PARTNER_QUOTA_TRANSACTION_FRAGMENT}
`;

export const ORGANIZATION_PARTNER_FORM_FRAGMENT = gql`
  fragment OrganizationPartnerFormConfigData on OrganizationPartnerFormConfig {
    id
    code
    isActive
    organizationName
    createdAt
    updatedAt
  }
`;

export const TEMPORARY_PARTNER = gql`
  fragment TemporaryPartnerData on Partner {
    id
    usage
    user {
      ... on User {
        address
        birthDate
        document
        email
        firstName
        gender
        lastName
        phoneNumber
        avatar {
          ... on Image {
            url
          }
        }
      }
    }
    updatedAt
    createdAt
  }
`;
