import styled, { css } from 'styled-components';
import { from } from 'styles/responsive';
import DefaultIcon from 'components/Icon';
import { TABLE_METRICS } from './constants';

export const Container = styled.div`
  display: flex;
  padding: 1rem 0 1.1rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey500}0A;
  border-top: solid 1px ${({ theme }) => theme.colors.grey200};
  margin-top: 1rem;
`;

const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ColumnTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.grey700};
  font-weight: ${({ theme }) => theme.weights.regular};
  ${TextEllipsis};

  ${from.tabletPortrait`
     font-size: 1rem;
  `};
`;

export const Name = styled(ColumnTitle)`
  cursor: pointer;
  flex: 0 0 ${TABLE_METRICS.name};
  ${TextEllipsis};

  ${from.tabletPortrait`
     flex: 0 0 ${TABLE_METRICS.name};
  `};
`;

export const CurrentQuantity = styled(ColumnTitle)`
  cursor: pointer;
  flex: 0 0 ${TABLE_METRICS.currentQuantity};
  white-space: pre-wrap;
`;

export const Price = styled(ColumnTitle)`
  cursor: pointer;
  flex: 0 0 ${TABLE_METRICS.price};
  white-space: pre-wrap;
`;

export const Icon = styled(DefaultIcon)``;
