import styled, { css } from 'styled-components';
import DefaultPrice from 'components/Price';

export const Blocks = styled.div`
  box-sizing: border-box;
  gap: 2rem;
  margin: 0 auto;
  padding: 5rem 1rem 0 1rem;
  width: 100%;
`;

export const Block = styled.div<{
  $color?: string;
  $backgroundColor?: string;
  $useLogoBackground: boolean;
}>`
  border: 2px solid
    ${({ $color, theme }) => $color || theme.colors.menu.imageBorder};
  border-radius: 0.5rem;
  box-sizing: border-box;
  padding: 1rem 1.5rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0,
    rgba(60, 64, 67, 0.15) 0 2px 6px 2px;

  ${({ $useLogoBackground, $backgroundColor, theme }) =>
    $useLogoBackground &&
    css`
      background-color: ${$backgroundColor || theme.colors.menu.background}F0;
    `}
`;

export const BlockTitle = styled.h3<{ $color?: string }>`
  color: ${({ $color, theme }) => $color || theme.colors.menu.categoryName};
  text-align: center;
  margin: 1rem 0;
  font-size: 1.3rem;
  text-transform: capitalize;
`;

export const BlockProducts = styled.div<{ $color?: string }>`
  display: flex;
  flex-flow: column;
  gap: 1rem;
`;

export const BlockProduct = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

export const ProductTitle = styled.div<{ $color?: string }>`
  display: flex;
  flex-flow: column;
  color: ${({ $color, theme }) => $color || theme.colors.menu.productTitle};
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 600;
`;

export const ProductReference = styled.span`
  font-size: 0.8rem;
  margin-top: 0.3rem;
`;

export const ProductPriceContent = styled.div`
  align-items: center;
  display: flex;
  margin-left: 4rem;
`;

export const OriginalPrice = styled.span`
  color: ${({ theme }) => theme.colors.danger};
  font-weight: 600;
  position: relative;
  margin-left: 0.3rem;

  &:before {
    border-color: inherit;
    border-top: 1px solid;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 45%;
    transform: skewY(-10deg);
  }
`;

export const Price = styled(DefaultPrice)<{ $color?: string }>`
  color: ${({ $color, theme }) => $color || theme.colors.menu.productPrice};
  font-weight: 600;
  font-size: 0.9rem;
`;

export const OrganizationLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export const Logo = styled.img`
  max-width: 9rem;
  max-height: 4rem;
`;
