import { ProductsListType, UserGender } from 'apollo/generated/globalTypes';
import type { UserData } from 'apollo/generated/UserData';
import type { PartnerData_user } from 'apollo/generated/PartnerData';
import type { EmployeeData_user } from 'apollo/generated/EmployeeData';
import {
  PermissionsGroupDataFragment,
  ProductOrderType,
} from 'apollo/graphql.types';
import { normalizeOrganizationSubscription } from './Organization';
import { normalizePermissions } from './PermissionsGroup';

type Input = UserData | PartnerData_user | EmployeeData_user;

export const normalizeBaseUser = (input: Input) => ({
  address: input.address || '',
  avatarImageId: input.avatar?.id || '',
  avatarImageSize: input.avatar?.file_size || 0,
  avatarImageUrl: input.avatar?.url || '',
  birthDate: input?.birthDate ? new Date(input.birthDate) : new Date(),
  createdAt: input?.createdAt ? new Date(input.createdAt) : new Date(),
  city: input.city || '',
  country: input.country || '',
  document: input.document || '',
  email: input.email || '',
  firstName: input.firstName || '',
  gender: input?.gender || UserGender.OTHER,
  fullName: `${input.firstName} ${input.lastName}`,
  lastName: input.lastName || '',
  // @ts-ignore
  role: input?.role,
  // @ts-ignore
  isActive: input.isActive || false,
  phoneNumber: input.phoneNumber || '',
  zipCode: input.zipCode || '',
  organization:
    'organization' in input
      ? {
          config: {
            creditConcepts: input?.organization?.config?.creditConcepts || [],
          },
        }
      : null,
});

export const normalizeUser = (input: UserData) => ({
  avatarImageUrl: input?.avatar?.url || '',
  document: input?.document || '',
  email: input?.email || '',
  firstName: input?.firstName || '',
  fullName: `${input?.firstName} ${input?.lastName}`,
  id: input?.id || '',
  lastName: input?.lastName || '',
  phoneNumber: input?.phoneNumber || '',
  role: input?.role,
  zipCode: input?.zipCode || '',
  organization: {
    name: input?.organization?.name || '',
    isOrdersActive: input?.organization?.isOrdersActive || false,
    totalOrders: input?.organization?.totalOrders || 0,
    document: input?.organization?.document || '',
    state: input?.organization?.state,
    id: input?.organization.id || '',
    isActive: input?.organization?.isActive || false,
    logoImageId: input?.organization?.logo?.id || '',
    logoImageSize: input?.organization?.logo?.file_size || 0,
    logoImageUrl: input?.organization?.logo?.url || '',
    config: input?.organization?.config
      ? {
          allowNegativeCredits:
            input?.organization?.config.allowNegativeCredits || false,
          activateRfidReader:
            // @ts-ignore
            input?.organization?.config.activateRfidReader || false,
          activateProductReference:
            // @ts-ignore
            input?.organization?.config.activateProductReference || false,
          forceOpenCashier:
            // @ts-ignore
            input?.organization?.config.forceOpenCashier || false,
          activeStrictCashier:
            // @ts-ignore
            input?.organization?.config.activeStrictCashier || false,
          allowExtraQuantity:
            // @ts-ignore
            input?.organization?.config.allowExtraQuantity || false,
          maxExtraQuantity:
            // @ts-ignore
            input?.organization?.config.maxExtraQuantity || '',
          creditConcepts: input.organization?.config.creditConcepts || [],
          deleteExpiredPartner:
            input?.organization?.config.deleteExpiredPartner || false,
          daysToRemoveExpiredPartner:
            input?.organization?.config.daysToRemoveExpiredPartner || 0,
          reasignReleasedPartnerNumber:
            input?.organization?.config.reasignReleasedPartnerNumber || false,
          contractText: input?.organization?.config.contractText || '',
          productsListType:
            input?.organization?.config.productsListType ||
            ProductsListType.LANDSCAPE,
          hasDriveIntegration:
            // @ts-ignore
            input?.organization?.hasDriveIntegration || false,
          defaultProductsOrder:
            // @ts-ignore
            input?.organization?.config.defaultProductsOrder ||
            ProductOrderType.CreatedAt,
        }
      : null,
    address: input?.organization?.address || '',
    city: input?.organization?.city || '',
    locality: input?.organization?.locality || '',
    zipCode: input?.organization?.zipCode || '',
    email: input?.organization?.email || '',
    subscription: normalizeOrganizationSubscription(
      input?.organization?.subscription,
    ),
  },
  employee: input?.employee
    ? {
        permissions: normalizePermissions(
          input.employee?.permissions as PermissionsGroupDataFragment,
        ),
      }
    : null,
});

export type User = ReturnType<typeof normalizeUser>;
