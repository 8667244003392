import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'notistack';
import useProducts from 'apollo/hooks/product/useProducts';
import useModal from 'apollo/hooks/useModal';
import {
  orderVar,
  permissionsStateVar,
  organizationConfigVar,
} from 'apollo/reactive';
import useMostUsedCategories from 'apollo/hooks/category/useMostUsedCategories';
import { applyDiscount } from 'utils/discounts';
import { ProductOrderBy, ProductOrderDirection } from 'apollo/graphql.types';
import { useSelectedProducts } from './logic';

export const useConnect = () => {
  const { openNewOrder, openNewProduct: openNewProductModal } = useModal();
  const { products, refetch, loading } = useProducts();
  const { mostUsedCategories } = useMostUsedCategories();
  const navigate = useNavigate();
  const {
    clearSelectedProducts,
    getSelectedProducts,
    isProductSelected,
    handleProductClick,
    selectedProductCount,
  } = useSelectedProducts();
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const [isEditing, setIsEditing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isRefetching, setIsRefetching] = useState(false);
  const [filter, setFilter] = useState('');
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [inactiveProductsFilter, setInactiveProductsFilter] = useState(false);
  const [orderBy, setOrderBy] = useState<ProductOrderBy | null>({
    type: organizationConfig.defaultProductsOrder,
    direction: ProductOrderDirection.Desc,
  });

  useEffect(() => {
    setIsRefetching(true);
    try {
      refetch({
        filters: {
          keyword: filter,
          categories: selectedCategories,
          isActive: !inactiveProductsFilter,
          orderBy: orderBy as any,
        },
      });
      setIsRefetching(false);
    } catch (error) {
      setIsRefetching(false);
      enqueueSnackbar('No se puede realizar la búsqueda', { variant: 'error' });
    }
  }, [
    enqueueSnackbar,
    filter,
    refetch,
    selectedCategories,
    inactiveProductsFilter,
    orderBy,
  ]);

  const handleEditButtonClick = useCallback(() => {
    clearSelectedProducts();
    setIsEditing((value) => !value);
  }, [clearSelectedProducts, setIsEditing]);

  const handleOnClickRow = useCallback(
    (id: string) => {
      if (isEditing) {
        navigate(`/products/${id}?section=profile`);
      } else {
        const selectedProduct = products.find((product) => product.id === id);
        if (selectedProduct && selectedProduct?.quantity > 0) {
          handleProductClick(id);
        } else {
          enqueueSnackbar('Producto agotado', {
            variant: 'info',
          });
        }
      }
    },
    [isEditing, navigate, products, handleProductClick, enqueueSnackbar],
  );

  const handleOnClickFloatingOrder = useCallback(() => {
    const selectedProducts = getSelectedProducts(products);
    const mappedProducts =
      selectedProducts?.map((p) => {
        const hasMoreOfOneUnit = p.quantity > 1;
        let priceToDisplay = hasMoreOfOneUnit ? p.price : p.price * p.quantity;

        priceToDisplay = p.discount
          ? applyDiscount({ discount: p.discount, price: priceToDisplay })
          : priceToDisplay;

        const productPrice = p.discount
          ? applyDiscount({ discount: p.discount, price: p.price })
          : p.price;

        return {
          price: priceToDisplay,
          productPrice,
          amount: hasMoreOfOneUnit ? 1 : p.quantity,
          extraAmount: 0,

          // product data to display
          id: p.id,
          name: p.name,
          originalPrice: p.price,
          referenceCode: p.referenceCode,
          productQuantity: p.quantity,
          discount: p.discount,
          selectedProductDiscount: p.discount,
          countToMaxConsume: p.countToMaxConsume,
        };
      }) || [];
    orderVar({
      products: mappedProducts,
      partner: null,
      orderDiscount: null,
      productDiscount: null,
    });
    openNewOrder();
    clearSelectedProducts();
  }, [clearSelectedProducts, getSelectedProducts, openNewOrder, products]);

  const orderTotal = useMemo(() => {
    const selectedProducts = getSelectedProducts(products);
    return selectedProducts.reduce(
      (count, product) => count + product.price,
      0,
    );
  }, [products, getSelectedProducts]);

  const handleSearch = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const newSearch = e.target.value;
    setFilter(newSearch);
  }, []);

  const isSelectedCategory = useCallback(
    (name: string) =>
      !!selectedCategories.find((category) => category === name),
    [selectedCategories],
  );

  const handleToggleCategory = useCallback(
    async (name: string) => {
      const exists = isSelectedCategory(name);
      if (exists) {
        setSelectedCategories((prev) =>
          prev.filter((category) => category !== name),
        );
      } else {
        setSelectedCategories((prev) => [...prev, name]);
      }
    },
    [isSelectedCategory],
  );

  const handleToggleInactiveProducts = useCallback(async () => {
    setInactiveProductsFilter((prev) => !prev);
  }, []);

  const handleChangeOrder = useCallback((order: ProductOrderBy) => {
    setOrderBy(order);
  }, []);

  return {
    employeePermissions,
    products,
    getSelectedProducts,
    handle: {
      editButtonClick: handleEditButtonClick,
      productClick: handleOnClickRow,
      orderClick: handleOnClickFloatingOrder,
      search: handleSearch,
      toggleCategory: handleToggleCategory,
      toggleInactiveProducts: handleToggleInactiveProducts,
      changeOrder: handleChangeOrder,
    },
    filter,
    isEditing,
    inactiveProductsFilter,
    isLoading: loading,
    isProductSelected,
    isRefetching,
    isSelectedCategory,
    mostUsedCategories,
    openNewProductModal,
    orderTotal,
    selectedProductCount,
    handleToggleCategory,
    orderBy,
    productsDisplayType: organizationConfig.productsListType,
  };
};
