import type { FC } from 'react';
import { useReactiveVar } from '@apollo/client';
import { organizationConfigVar } from 'apollo/reactive';
import { ProductsListType } from 'apollo/generated/globalTypes';
import LandscapeFormat from './LandscapeFormat';
import useConnect from './connect';
import TableHead from './TableHead';
import {
  CategoriesFilter,
  CategoryFilter,
  CategoryName,
  Container,
  Filters,
  FiltersContainer,
  ReturnButton,
  Rows,
  RowsContainer,
  LandscapeListContent,
  ButtonContent,
} from './styles';
import Row from './Row';

const Products: FC = () => {
  const {
    handleClose,
    handleSelect,
    handleSearch,
    isOpen,
    isRefetching,
    isSelectedCategory,
    products,
    filter,
    mostUsedCategories,
    handleToggleCategory,
    productsDisplayType,
    orderBy,
    handleChangeOrder,
  } = useConnect();

  const organizationConfig = useReactiveVar(organizationConfigVar);
  const hasMostUsedCategories = mostUsedCategories.length > 0;

  return (
    <Container open={isOpen} onClose={handleClose}>
      <FiltersContainer>
        <Filters
          isLoading={isRefetching}
          onSearchChange={handleSearch}
          filter={filter}
          nResults={products?.length || 0}
        />
        {hasMostUsedCategories && (
          <CategoriesFilter>
            {mostUsedCategories.map((category) => (
              <CategoryFilter
                key={category.name}
                $selected={isSelectedCategory(category.name)}
                onClick={() => handleToggleCategory(category.name)}
              >
                <CategoryName>{category.name}</CategoryName>
              </CategoryFilter>
            ))}
          </CategoriesFilter>
        )}

        <TableHead currentOrder={orderBy} onChangeOrder={handleChangeOrder} />
      </FiltersContainer>
      <RowsContainer>
        {productsDisplayType === ProductsListType.LANDSCAPE && (
          <LandscapeListContent>
            {products.map((product) => (
              <LandscapeFormat
                key={product.id}
                product={product}
                onSelect={() => handleSelect(product)}
                activateProductReference={
                  organizationConfig.activateProductReference
                }
              />
            ))}
          </LandscapeListContent>
        )}

        {productsDisplayType === ProductsListType.LIST && (
          <Rows>
            {products.map((product) => (
              <Row
                key={product.id}
                product={product}
                onSelect={() => handleSelect(product)}
                activateProductReference={
                  organizationConfig.activateProductReference
                }
              />
            ))}
          </Rows>
        )}
      </RowsContainer>
      <ButtonContent>
        <ReturnButton onClick={handleClose}>Volver</ReturnButton>
      </ButtonContent>
    </Container>
  );
};

export default Products;
