import styled from 'styled-components';
import { SectionHeader } from 'components/Layout';
import DefaultIcon from 'components/Icon';
import { from } from 'styles/responsive';
import DefaultBackLink from 'components/BackLinkV2';
import DefaultButton from 'components/Button';

export const Header = styled(SectionHeader)`
  align-items: center;
  padding: 0;

  ${from.tabletLandscape`
    margin-bottom: 3rem;
  `}
`;

export const BackLink = styled(DefaultBackLink)``;

export const AvatarContainer = styled.div`
  margin: 0 1rem;
`;

export const HeaderButtons = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-left: auto;

  > :not(:last-child) {
    margin-right: 1rem !important;
  }
  > * {
    flex: 0 0 8rem;

    ${from.desktop`
      flex: 0 1 12.5rem;
    `}

    ${from.tabletPortrait`
      justify-content: flex-start;
    `}
  }
`;

export const HeaderInfo = styled.div`
  flex: 1 0 auto;
  margin-left: 1rem;
`;

export const HeaderName = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin: 0;
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TimerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row;
`;

export const TimerIcon = styled(DefaultIcon)<{ $isJoined: boolean }>`
  color: ${({ $isJoined, theme }) =>
    $isJoined ? theme.colors.green : theme.colors.primary};
  cursor: ${({ $isJoined }) => ($isJoined ? 'unset' : 'pointer')};
  margin-right: 0.5rem;
`;

export const LastRegister = styled.span`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 0.8rem;
`;

export const OrderButton = styled(DefaultButton)``;

export const SecondaryButton = styled(DefaultButton).attrs(() => ({
  variant: 'light_black',
}))`
  &&& {
    max-width: 10%;
  }
`;
