import { useCallback, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const BarCodeRef = useRef(null);
  const { close, generateQRPayload, type } = useModal();
  const handleOnPrint = useReactToPrint({
    content: () => BarCodeRef.current,
  });

  const { value } = generateQRPayload || { value: '' };

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  return {
    handleOnPrint,
    handleClose,
    BarCodeRef,
    value,
    isOpen: type === ModalType.SHOW_BAR_CODE,
  };
};

export default useConnect;
