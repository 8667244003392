import { FC, memo, useCallback } from 'react';
import { useMediaQuery } from '@mui/material';
import { ProductOrderDirection, ProductOrderType } from 'apollo/graphql.types';
import { from } from 'styles/responsive';
import { Container, Name, Icon, CurrentQuantity, Price } from './styles';
import type { Props } from './types';

const TableHead: FC<Props> = ({ className, currentOrder, onChangeOrder }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);

  const handleChangeOrder = useCallback(
    (type: ProductOrderType) => {
      let direction = ProductOrderDirection.Asc;
      if (
        currentOrder?.type === type &&
        currentOrder?.direction === ProductOrderDirection.Asc
      ) {
        direction = ProductOrderDirection.Desc;
      }

      onChangeOrder({ direction, type });
    },
    [currentOrder, onChangeOrder],
  );

  const handleGetIconName = useCallback(
    (type: ProductOrderType) => {
      if (!currentOrder || currentOrder.type !== type) {
        return 'up';
      }

      return currentOrder.direction === ProductOrderDirection.Asc
        ? 'down'
        : 'up';
    },
    [currentOrder],
  );

  return (
    <Container className={className}>
      <Name onClick={() => handleChangeOrder(ProductOrderType.Name)}>
        <Icon
          size={fromTabletPortrait ? 18 : 12}
          name={handleGetIconName(ProductOrderType.Name)}
        />
        Nombre
      </Name>
      <CurrentQuantity
        onClick={() => handleChangeOrder(ProductOrderType.Quantity)}
      >
        <Icon
          size={fromTabletPortrait ? 18 : 12}
          name={handleGetIconName(ProductOrderType.Quantity)}
        />
        Cantidad
      </CurrentQuantity>
      <Price onClick={() => handleChangeOrder(ProductOrderType.Price)}>
        <Icon
          size={fromTabletPortrait ? 18 : 12}
          name={handleGetIconName(ProductOrderType.Price)}
        />
        Precio
      </Price>
    </Container>
  );
};

export default memo(TableHead);
