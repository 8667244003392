import { FC, memo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { organizationConfigVar } from 'apollo/reactive';
import {
  BackLink,
  Header,
  HeaderButtons,
  HeaderInfo,
  HeaderName,
  Button,
  SecondaryButton,
  HeaderReference,
} from './styles';
import { Props } from './types';

const ProductHeader: FC<Props> = ({
  fromTabletPortrait,
  name,
  referenceCode,
  onAdjust,
  onOpenAdvancedMenu,
}) => {
  const organizationConfig = useReactiveVar(organizationConfigVar);

  return (
    <>
      <Header>
        <BackLink size={12} />
        <HeaderInfo>
          <HeaderName>
            {name}{' '}
            {organizationConfig?.activateProductReference && referenceCode && (
              <HeaderReference>#{referenceCode}</HeaderReference>
            )}
          </HeaderName>
        </HeaderInfo>
        {fromTabletPortrait && (
          <HeaderButtons>
            <Button variant="light" onClick={onAdjust}>
              Ajustar
            </Button>

            <SecondaryButton onClick={onOpenAdvancedMenu}>...</SecondaryButton>
          </HeaderButtons>
        )}
        {!fromTabletPortrait && (
          <SecondaryButton onClick={onOpenAdvancedMenu}>...</SecondaryButton>
        )}
      </Header>
    </>
  );
};

export default memo(ProductHeader);
