import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import format from 'date-fns/format';
import {
  ADD_SCHEDULE_TO_EMPLOYEE,
  REMOVE_SCHEDULE_EMPLOYEE,
  CONVERT_PARTNER_TO_EMPLOYEE,
  REMOVE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  UPDATE_PASSWORD,
  TOGGLE_EMPLOYEE_ACTIVATION,
} from 'apollo/mutations/employees';
import { GET_EMPLOYEE } from 'apollo/queries/employees';
import type {
  EmployeeFormFields,
  WorkScheduleFormFields,
} from 'model/Employee';
import type {
  RemoveEmployee,
  RemoveEmployeeVariables,
} from 'apollo/generated/RemoveEmployee';
import type {
  UpdateEmployee,
  UpdateEmployeeVariables,
} from 'apollo/generated/UpdateEmployee';
import type {
  UpdatePassword,
  UpdatePasswordVariables,
} from 'apollo/generated/UpdatePassword';
import type {
  ConvertToEmployee,
  ConvertToEmployeeVariables,
} from 'apollo/generated/ConvertToEmployee';
import type {
  AddScheduleToEmployee,
  AddScheduleToEmployeeVariables,
} from 'apollo/generated/AddScheduleToEmployee';
import type {
  RemoveScheduleEmployee,
  RemoveScheduleEmployeeVariables,
} from 'apollo/generated/RemoveScheduleEmployee';
import type {
  ToggleEmployeeActivationMutation,
  ToggleEmployeeActivationMutationVariables,
} from 'apollo/graphql.types';

const useEmployeeActions = () => {
  const [removeEmployeeMutation, { loading: isLoadingRemoveEmployee }] =
    useMutation<RemoveEmployee, RemoveEmployeeVariables>(REMOVE_EMPLOYEE);
  const [updateEmployeeMutation, { loading: isLoadingUpdateEmployee }] =
    useMutation<UpdateEmployee, UpdateEmployeeVariables>(UPDATE_EMPLOYEE);
  const [
    toggleEmployeeActivationMutation,
    { loading: isLoadingToggleEmployeeActivation },
  ] = useMutation<
    ToggleEmployeeActivationMutation,
    ToggleEmployeeActivationMutationVariables
  >(TOGGLE_EMPLOYEE_ACTIVATION, { refetchQueries: [GET_EMPLOYEE] });
  const [updatePasswordMutation, { loading: isLoadingUpdatePassword }] =
    useMutation<UpdatePassword, UpdatePasswordVariables>(UPDATE_PASSWORD);
  const [convertToEmployeeMutation, { loading: isLoadingConvertToEmployee }] =
    useMutation<ConvertToEmployee, ConvertToEmployeeVariables>(
      CONVERT_PARTNER_TO_EMPLOYEE,
    );
  const [
    addScheduleToEmployeeMutation,
    { loading: isLoadingAddScheduleToEmployee },
  ] = useMutation<AddScheduleToEmployee, AddScheduleToEmployeeVariables>(
    ADD_SCHEDULE_TO_EMPLOYEE,
    { refetchQueries: [GET_EMPLOYEE] },
  );
  const [removeScheduleMutation, { loading: isLoadingRemoveSchedule }] =
    useMutation<RemoveScheduleEmployee, RemoveScheduleEmployeeVariables>(
      REMOVE_SCHEDULE_EMPLOYEE,
      { refetchQueries: [GET_EMPLOYEE] },
    );

  const normalizeFormValuesToUpdate = useCallback(
    (values: EmployeeFormFields) => ({
      document: values.document,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      permissionsGroupId: values.permissionsGroupId,
    }),
    [],
  );

  const updateEmployee = useCallback(
    async ({ id, data }: { id: string; data: EmployeeFormFields }) => {
      const dto = normalizeFormValuesToUpdate(data);
      await updateEmployeeMutation({
        variables: {
          id,
          data: dto,
        },
      });
    },
    [normalizeFormValuesToUpdate, updateEmployeeMutation],
  );

  const toggleEmployeeActivation = useCallback(
    async (id: string) => {
      await toggleEmployeeActivationMutation({
        variables: {
          id,
        },
      });
    },
    [toggleEmployeeActivationMutation],
  );

  const updatePassword = useCallback(
    async ({
      id,
      data,
    }: {
      id: string;
      data: { newPassword: string; oldPassword: string };
    }) => {
      await updatePasswordMutation({
        variables: {
          id,
          data,
        },
      });
    },
    [updatePasswordMutation],
  );

  const deleteEmployee = useCallback(
    async (id: string) => {
      await removeEmployeeMutation({
        variables: {
          id,
        },
      });
    },
    [removeEmployeeMutation],
  );

  const updateEmployeeAvatar = useCallback(
    async ({ id, imageId }: { id: string; imageId: string }) => {
      await updateEmployeeMutation({
        variables: {
          id,
          data: {
            avatarId: imageId,
          },
        },
      });
    },
    [updateEmployeeMutation],
  );

  const convertPartnerToEmployee = useCallback(
    async (partnerId: string) => {
      const { data } = await convertToEmployeeMutation({
        variables: {
          partnerId,
        },
      });

      if (data?.convertToEmployee) {
        return data.convertToEmployee;
      }
    },
    [convertToEmployeeMutation],
  );

  const addScheduleToEmployee = useCallback(
    async ({
      employeeId,
      input,
    }: {
      employeeId: string;
      input: WorkScheduleFormFields;
    }) => {
      const dto = {
        daysOfWeek: input.daysOfWeek,
        schedules: input.schedules
          .filter((schedule) => schedule.startTime && schedule.endTime)
          .map((schedule) => ({
            startTime: format(new Date(schedule.startTime), 'HH:mm:ss'),
            endTime: format(new Date(schedule.endTime), 'HH:mm:ss'),
          })),
      };

      const { data } = await addScheduleToEmployeeMutation({
        variables: {
          employeeId,
          data: dto,
        },
      });

      if (data?.addScheduleToEmployee) {
        return data.addScheduleToEmployee;
      }
    },
    [addScheduleToEmployeeMutation],
  );

  const removeSchedule = useCallback(
    async (scheduleId: string) => {
      await removeScheduleMutation({
        variables: {
          scheduleId,
        },
      });
    },
    [removeScheduleMutation],
  );

  return {
    toggleEmployeeActivation,
    addScheduleToEmployee,
    convertPartnerToEmployee,
    deleteEmployee,
    removeSchedule,
    updateEmployee,
    updateEmployeeAvatar,
    updatePassword,
    loading:
      isLoadingRemoveEmployee ||
      isLoadingUpdateEmployee ||
      isLoadingUpdatePassword ||
      isLoadingConvertToEmployee ||
      isLoadingAddScheduleToEmployee ||
      isLoadingRemoveSchedule ||
      isLoadingToggleEmployeeActivation,
  };
};

export default useEmployeeActions;
