import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  organizationConfigVar,
  permissionsStateVar,
  subscriptionStateVar,
} from 'apollo/reactive';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const { close, productAdvancedMenuPayload, type } = useModal();
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const { activateProductReference } = useReactiveVar(organizationConfigVar);
  const subscription = useReactiveVar(subscriptionStateVar);

  const {
    onAdjust,
    onRemove,
    onStoreMovement,
    assignDiscount,
    onCreateReference,
    onBaseWeightMovement,
    showBarCode,
  } = productAdvancedMenuPayload || {};

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleRemove = useCallback(() => {
    if (onRemove) {
      onRemove();
    }
  }, [onRemove]);

  const handleAdjust = useCallback(() => {
    if (onAdjust) {
      onAdjust();
    }
  }, [onAdjust]);

  const handleStoreMovement = useCallback(() => {
    if (onStoreMovement) {
      onStoreMovement();
    }
  }, [onStoreMovement]);

  const handleCreateReference = useCallback(() => {
    if (onCreateReference && activateProductReference) {
      onCreateReference();
    }
  }, [activateProductReference, onCreateReference]);

  const handleOpenAssignDiscount = useCallback(() => {
    if (assignDiscount) {
      assignDiscount();
    }
  }, [assignDiscount]);

  const handleBaseWeightMovement = useCallback(() => {
    if (onBaseWeightMovement) {
      onBaseWeightMovement();
    }
  }, [onBaseWeightMovement]);

  const handleOpenBarCode = useCallback(() => {
    if (showBarCode) {
      showBarCode();
    }
  }, [showBarCode]);

  return {
    activateProductReference,
    handleClose,
    handleRemove,
    handleAdjust,
    handleCreateReference,
    isOpen: type === ModalType.PRODUCT_ADVANCED_MENU,
    employeePermissions,
    handleStoreMovement,
    subscription,
    handleOpenAssignDiscount,
    handleBaseWeightMovement,
    handleOpenBarCode,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
