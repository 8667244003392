import type { FC, ChangeEvent } from 'react';
import { FieldArray, Formik } from 'formik';
import useGlobalKeyPress from 'hooks/useGlobalKeyPress';
import { applyDiscount } from 'utils/discounts';
import { formatPrice } from 'utils/price';
import Price from 'components/Price';
import OrderPlaceholder from './Placeholder';
import useConnect from './connect';
import * as S from './styles';
import { AMOUNT_BUTTON_TYPE } from './types';

const NewOrder: FC = () => {
  const {
    discount,
    formikRef,
    handles,
    initialFormValues,
    isLoading,
    isOpen,
    partner,
    products,
    showPartnerError,
    subscription,
    addCreditsLoading,
    partnerCredits,
    organizationConfig,
  } = useConnect();

  const hasProducts = products.length > 0;
  const hasDiscount = !!discount;
  const totalPrice = initialFormValues.products.reduce(
    (a, b) => a + b.price,
    0,
  );
  const originalPrice = totalPrice;
  const finalPrice = !hasDiscount
    ? originalPrice
    : applyDiscount({
        discount,
        price: totalPrice,
      });

  useGlobalKeyPress(isOpen, handles.handleCodeReceived);

  window.addEventListener('keypress', (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  });

  return (
    <S.Container
      disableBackdropClick
      disableEscapeKeyDown
      open={isOpen}
      onClose={handles.handleClose}
    >
      <S.Header>
        <S.Title>Nuevo pedido</S.Title>
        {hasProducts && (
          <>
            <S.Dot>·</S.Dot>
            <S.TotalProducts>{products.length} Productos</S.TotalProducts>
          </>
        )}
      </S.Header>
      {!hasProducts ? (
        <S.EmptyState
          buttonLabel="Añadir producto"
          onClickButton={handles.handleAddProduct}
        >
          Aun no has seleccionado ningún producto, selecciona al menos uno para
          realizar un pedido
        </S.EmptyState>
      ) : (
        <S.ProductsContainer>
          <S.ProductsTitleRow>
            <S.TitleContainer>
              <S.AddMoreButton onClick={handles.handleAddProduct}>
                Añadir
              </S.AddMoreButton>
              {subscription.allowedDiscounts && (
                <S.AddDiscountButton
                  $hasDiscount={hasDiscount}
                  onClick={handles.handleAddDiscount}
                >
                  %
                </S.AddDiscountButton>
              )}
            </S.TitleContainer>
            <S.TotalPrice>
              {discount && (
                <S.OriginalPrice>
                  <Price>{Number(originalPrice.toFixed(2))}</Price>
                </S.OriginalPrice>
              )}{' '}
              <Price>{Number(finalPrice.toFixed(2))}</Price>
            </S.TotalPrice>
          </S.ProductsTitleRow>
          <S.Products>
            <Formik
              onSubmit={handles.handlePurchase}
              initialValues={initialFormValues}
              innerRef={formikRef}
              enableReinitialize
            >
              {({ values, setFieldValue }) => (
                <FieldArray
                  name="products"
                  render={() =>
                    values?.products?.length > 0 &&
                    values.products.map((product, index) => {
                      const finalProductCost = product.discount
                        ? applyDiscount({
                            discount: product.discount,
                            price: product.costs,
                          })
                        : product.costs;
                      const showReference = Boolean(
                        organizationConfig?.activateProductReference &&
                          product.referenceCode,
                      );

                      const handleChangePrice = (
                        e: ChangeEvent<HTMLInputElement>,
                      ) => {
                        handles.handleChangeProductInput({
                          e,
                          productIndex: index,
                          actionType: 'PRICE',
                        });
                      };

                      const handleChangeInputAmount = (
                        e: ChangeEvent<HTMLInputElement>,
                      ) => {
                        handles.handleChangeProductInput({
                          e,
                          productIndex: index,
                          actionType: 'AMOUNT',
                        });
                      };

                      return (
                        <S.ProductRow key={product.id}>
                          <S.ProductNameRow>
                            <S.ProductName>
                              {product.name}{' '}
                              {showReference && (
                                <S.ProductReference>
                                  #{product.referenceCode}
                                </S.ProductReference>
                              )}
                            </S.ProductName>
                            <S.ProductData>
                              {product.discount && (
                                <S.OriginalProductPrice>
                                  <S.ProductNamePrice>
                                    {product.costs}
                                  </S.ProductNamePrice>
                                </S.OriginalProductPrice>
                              )}{' '}
                              <S.ProductNamePrice>
                                {finalProductCost}
                              </S.ProductNamePrice>
                              <S.Dot>·</S.Dot>
                              <S.ProductQuantity>
                                {product.quantity.toFixed(2)} unidades
                              </S.ProductQuantity>
                            </S.ProductData>
                          </S.ProductNameRow>
                          <S.ProductAction>
                            <S.Quantity>
                              <S.IconButton
                                onClick={() =>
                                  handles.handleChangeAmount(
                                    index,
                                    AMOUNT_BUTTON_TYPE.SUBSTRACT,
                                  )
                                }
                                disabled={handles.handleDisableAmountButton(
                                  index,
                                  AMOUNT_BUTTON_TYPE.SUBSTRACT,
                                )}
                                type="button"
                              >
                                <S.SubstractIcon />
                              </S.IconButton>
                              <S.TextFieldNumber
                                name={`products[${index}].amount`}
                                id={`products[${index}].amount`}
                                type="number"
                                onLocalChange={handleChangeInputAmount}
                              />
                              <S.IconButton
                                onClick={() =>
                                  handles.handleChangeAmount(
                                    index,
                                    AMOUNT_BUTTON_TYPE.SUM,
                                  )
                                }
                                disabled={handles.handleDisableAmountButton(
                                  index,
                                  AMOUNT_BUTTON_TYPE.SUM,
                                )}
                                type="button"
                              >
                                <S.AddIcon />
                              </S.IconButton>
                              {organizationConfig.allowExtraQuantity &&
                                product.countToMaxConsume && (
                                  <S.ExtraQuantity>
                                    <S.ExtraQuantityText>
                                      Real:
                                    </S.ExtraQuantityText>
                                    <S.TextFieldNumber
                                      name={`products[${index}].extraAmount`}
                                      id={`products[${index}].extraAmount`}
                                      type="number"
                                      min={0}
                                      onLocalChange={(e) =>
                                        handles.handleChangeProductExtraAmount({
                                          e,
                                          setFieldValue,
                                          productIndex: index,
                                        })
                                      }
                                    />
                                  </S.ExtraQuantity>
                                )}
                            </S.Quantity>
                            <S.Credits>
                              <S.TextFieldNumber
                                name={`products[${index}].price`}
                                id={`products[${index}].price`}
                                type="number"
                                onLocalChange={handleChangePrice}
                              />
                              <S.Cr>cr</S.Cr>
                              {subscription.allowedDiscounts && (
                                <S.AddProductDiscountButton
                                  $hasDiscount={!!product?.discount}
                                  onClick={() =>
                                    handles.handleAddProductDiscount(index)
                                  }
                                >
                                  %
                                </S.AddProductDiscountButton>
                              )}
                              <S.RemoveIcon
                                onClick={() =>
                                  handles.handleRemoveProduct(product.id)
                                }
                                name="closeCircle"
                              />
                            </S.Credits>
                          </S.ProductAction>
                        </S.ProductRow>
                      );
                    })
                  }
                />
              )}
            </Formik>
          </S.Products>
        </S.ProductsContainer>
      )}
      <S.PartnerRow onClick={handles.handleSelectPartner}>
        <S.PartnerData>
          <S.Avatar
            useIcon
            size={3}
            src={partner?.avatarImageUrl}
            isSelected={!!partner?.id}
          />
          <S.PartnerName $isSelected={!!partner?.id}>
            {partner?.id
              ? `${partner.fullName}  #${partner.memberNum}`
              : 'Selecciona un socio'}
          </S.PartnerName>
        </S.PartnerData>
        <S.ArrowRightIcon />
      </S.PartnerRow>
      {partner?.id && (
        <S.CreditsRow>
          {addCreditsLoading ? (
            <OrderPlaceholder nRows={1} />
          ) : (
            <>
              <Formik
                onSubmit={handles.handleAddCreditsSubmit}
                initialValues={{ credits: '' as unknown as number }}
                validateOnBlur={false}
              >
                {({ handleSubmit, values }) => (
                  <S.CreditsForm onSubmit={handleSubmit}>
                    <S.CreditsInput
                      $isPositive={partnerCredits - finalPrice > 0}
                      placeholder={`${formatPrice(
                        partnerCredits - finalPrice,
                      )} cr`}
                      key="credits"
                      id="credits"
                      name="credits"
                      type="number"
                    />
                    <S.CreditsButton
                      type="submit"
                      variant="light"
                      disabled={values.credits <= 0 || values.credits > 1000}
                      isLoading={isLoading}
                    >
                      Añadir
                    </S.CreditsButton>
                  </S.CreditsForm>
                )}
              </Formik>
            </>
          )}
        </S.CreditsRow>
      )}

      {showPartnerError && (
        <S.ErrorText>Debes seleccionar un socio</S.ErrorText>
      )}
      <S.Buttons>
        <S.CancelButton onClick={handles.handleClose}>Cancelar</S.CancelButton>
        <S.ChargeButton
          disabled={products?.length === 0 || !partner}
          isLoading={isLoading}
          onClick={handles.handleOnSubmit}
        >
          Cobrar
        </S.ChargeButton>
      </S.Buttons>
    </S.Container>
  );
};

export default NewOrder;
