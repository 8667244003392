import { useCallback, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useEventActions from 'apollo/hooks/event/useEventActions';
import { permissionsStateVar } from 'apollo/reactive/permissionsState';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { formatErrors } from 'utils/errors/formatErrors';
import type { FormikConfig } from 'formik';
import type { EventFormFields } from 'model/OrganizationEvent';

const useConnect = () => {
  const { close, eventPayload, type } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const {
    loading: eventActionLoading,
    updateEvent,
    createEvent,
    deleteEvent,
  } = useEventActions();
  const employeePermissions = useReactiveVar(permissionsStateVar);

  const { event } = eventPayload || {};

  const initialValues = useMemo(
    () => ({
      title: event?.title || '',
      startDate:
        (event?.start && format(new Date(event.start), "yyyy-MM-dd'T'HH:mm")) ||
        format(new Date(), "yyyy-MM-dd'T'HH:mm"),
      endDate:
        (event?.end && format(new Date(event.end), "yyyy-MM-dd'T'HH:mm")) ||
        format(new Date(), "yyyy-MM-dd'T'HH:mm"),
      allDay: event?.allDay || false,
      notify: event?.notify || false,
      emails: event?.emails || '',
    }),
    [event],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback<FormikConfig<EventFormFields>['onSubmit']>(
    async (data, { setErrors }) => {
      if (data.emails) {
        const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const emails = data.emails
          .split(',')
          .map((email) => email.toLowerCase().trim());

        // eslint-disable-next-line no-restricted-syntax
        for (const email of emails) {
          if (!validEmailRegex.test(email)) {
            setErrors({ emails: 'Uno de los emails no es valido' });
            return;
          }
        }
      }

      if (event?.id && employeePermissions.allowEditCalendars) {
        try {
          await updateEvent({
            id: event.id,
            data,
          });
          enqueueSnackbar('Se ha actualizado el evento correctamente', {
            variant: 'success',
          });
          handleClose();
        } catch (e) {
          enqueueSnackbar(formatErrors('event', e.message, 'actualizar'), {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          });
        }
      } else if (!event?.id && employeePermissions.allowCreateCalendars) {
        try {
          await createEvent(data);
          enqueueSnackbar('Se ha creado el evento correctamente', {
            variant: 'success',
          });
          handleClose();
        } catch (e) {
          enqueueSnackbar(formatErrors('event', e.message, 'crear'), {
            variant: 'error',
            action: () => <NotifySnackbarErrorButton error={e} />,
          });
        }
      }
    },
    [
      createEvent,
      employeePermissions,
      enqueueSnackbar,
      event,
      handleClose,
      updateEvent,
    ],
  );

  const handleDelete = useCallback(async () => {
    try {
      if (event?.id) {
        await deleteEvent(event.id);
        enqueueSnackbar('Se ha eliminado el evento correctamente', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(formatErrors('event', e.message, 'eliminar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }

    handleClose();
  }, [deleteEvent, enqueueSnackbar, event, handleClose]);

  return {
    employeePermissions,
    initialValues,
    handleClose,
    handleSubmit,
    handleDelete,
    event,
    isOpen: type === ModalType.EVENT,
    isLoading: eventActionLoading,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
