import { FC, memo } from 'react';
import { Formik } from 'formik';
import { ProductsListType } from 'apollo/generated/globalTypes';
import TextField from 'components/Inputs/TextField';
import Switch from 'components/Inputs/Switch';
import Select from 'components/Inputs/Select';
import { ProductOrderType } from 'apollo/graphql.types';
import { Row, SubmitButton, Form } from '../styles';
import type { Props } from './types';

const BaseConfig: FC<Props> = ({ onSubmit, initialValues }) => {
  const formId = 'organizationConfig';

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({ values }) => (
        <Form>
          <Row>
            <Switch
              id={`${formId}_allowNegativeCredits`}
              label="Permitir transacciones con valores negativos"
              name="allowNegativeCredits"
            />
            <Switch
              id={`${formId}_reasignReleasedPartnerNumber`}
              label="Reasignar numeros de socios eliminados"
              name="reasignReleasedPartnerNumber"
            />
          </Row>
          <Row>
            <Switch
              id={`${formId}_activateRfidReader`}
              label="Activar lector RFID"
              name="activateRfidReader"
            />
            <Switch
              id={`${formId}_activateProductReference`}
              label="Activar referencia de productos"
              name="activateProductReference"
            />
          </Row>
          <Row>
            <Switch
              id={`${formId}_forceOpenCashier`}
              label="Forzar apertura de caja"
              name="forceOpenCashier"
            />
            <Switch
              id={`${formId}_activeStrictCashier`}
              label="Activar caja estricta"
              name="activeStrictCashier"
            />
          </Row>
          <Row>
            <Switch
              id={`${formId}_allowExtraQuantity`}
              label="Permitir cantidad real"
              name="allowExtraQuantity"
            />
            {values.allowExtraQuantity && (
              <TextField
                id={`${formId}_maxExtraQuantity`}
                label="Máximo extra permitido"
                name="maxExtraQuantity"
                type="number"
              />
            )}
          </Row>
          <Row>
            <Switch
              id={`${formId}_deleteExpiredPartner`}
              label="Eliminar socio sin cuota"
              name="deleteExpiredPartner"
            />
            {values.deleteExpiredPartner && (
              <TextField
                id={`${formId}_daysToRemoveExpiredPartner`}
                label="Numero de dias antes de eliminar un socio sin cuota"
                name="daysToRemoveExpiredPartner"
                type="number"
              />
            )}
          </Row>
          <Row>
            <Select
              name="productsListType"
              label="Tipo de lista de productos"
              id={`${formId}_productsListType`}
              options={[
                {
                  value: ProductsListType.LIST,
                  label: 'Lista',
                },
                {
                  value: ProductsListType.LANDSCAPE,
                  label: 'Cuadrado',
                },
              ]}
            />
            <Select
              name="defaultProductsOrder"
              label="Condición de orden"
              id={`${formId}_defaultProductsOrder`}
              options={[
                {
                  value: ProductOrderType.CreatedAt,
                  label: 'Fecha de creación',
                },
                {
                  value: ProductOrderType.Price,
                  label: 'Precio',
                },
                {
                  value: ProductOrderType.Quantity,
                  label: 'Cantidad',
                },
                {
                  value: ProductOrderType.Name,
                  label: 'Nombre',
                },
              ]}
            />
          </Row>

          <SubmitButton type="submit">Guardar</SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default memo(BaseConfig);
