import { useCallback, useEffect, useMemo, useState } from 'react';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import useCashiers from 'apollo/hooks/cashier/useCashiers';
import useModal from 'apollo/hooks/useModal';
import { permissionsStateVar, userRoleVar } from 'apollo/reactive';
import {
  cashierHeaders,
  getCashierData,
  getCashierFilename,
} from 'utils/csv/cashier';
import type { Cashier } from 'model/Cashier';

const useConnect = () => {
  const userRole = useReactiveVar(userRoleVar);
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const { openCashierDetails } = useModal();

  const [searchParams, setSearchParams] = useSearchParams();
  const startFromParam = searchParams.get('start');
  const endFromParam = searchParams.get('end');

  const [filterDates, setFilterDate] = useState<{ from?: Date; to?: Date }>({
    from: startFromParam
      ? new Date(Number(startFromParam))
      : startOfDay(subDays(new Date(), 3)),
    to: endFromParam ? new Date(Number(endFromParam)) : endOfDay(new Date()),
  });

  const { cashiers, getCashiersLoading, refetch } = useCashiers({
    filters: filterDates,
  });

  useEffect(() => {
    refetch({
      filters: {
        fromDate: filterDates.from || null,
        toDate: filterDates.to || null,
      },
    });
  }, [filterDates, refetch]);

  const handleOpenDetails = useCallback(
    (cashier: Cashier) => {
      openCashierDetails({ cashier });
    },
    [openCashierDetails],
  );

  const csvInfo = useMemo(() => {
    const data = getCashierData(cashiers);
    const filename = getCashierFilename(filterDates);

    return { headers: cashierHeaders, data, filename };
  }, [cashiers, filterDates]);

  const handleOnSearch = useCallback(
    async ({ from, to }: { from?: Date; to?: Date }) => {
      if (from || to) {
        const newSearchParams = new URLSearchParams(searchParams);
        if (from) {
          newSearchParams.set('start', `${from.getTime()}`);
        }
        if (to) {
          newSearchParams.set('end', `${to.getTime()}`);
        }
        setSearchParams(newSearchParams);
      }

      setFilterDate({ from, to });
    },
    [searchParams, setSearchParams],
  );

  return {
    cashiers,
    csvInfo,
    employeePermissions,
    filterDates,
    handleOnSearch,
    handleOpenDetails,
    isLoading: getCashiersLoading,
    userRole,
  };
};

export default useConnect;
