import { FC, memo } from 'react';
import Barcode from 'react-barcode';
import {
  PrintButton,
  Buttons,
  CancelButton,
  Container,
  BarCodeContent,
} from './styles';
import useConnect from './connect';

const GenerateBarCodeModal: FC = () => {
  const { handleOnPrint, isOpen, handleClose, value, BarCodeRef } =
    useConnect();

  return (
    <Container disableFullScreen open={isOpen} onClose={handleClose}>
      <BarCodeContent ref={BarCodeRef}>
        <Barcode width={2} height={70} fontSize={14} value={value} />
      </BarCodeContent>
      <Buttons>
        <CancelButton onClick={handleClose}>Cerrar</CancelButton>
        <PrintButton variant="light" onClick={handleOnPrint}>
          Imprimir
        </PrintButton>
      </Buttons>
    </Container>
  );
};

export default memo(GenerateBarCodeModal);
