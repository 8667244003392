import { OrderDataFragment } from 'apollo/graphql.types';
import { Discount } from './Discount';

export type EditOrderFields = Readonly<{
  products: {
    name: string;
    referenceCode: string;
    amount: number;
    extraAmount: number;
    price: number;
    productPrice: number;
    originalPrice: number;
    countToMaxConsume: boolean;
    id: string;
    quantity: number;
    costs: number;
    discount?: Partial<Discount> | null;
    selectedProductDiscount?: Partial<Discount> | null;
    isDiscountSet?: boolean;
  }[];
}>;

export const normalizeOrder = (input: OrderDataFragment) => {
  const quantity = Number(input.quantity);
  const extraQuantity = input?.extraQuantity ? Number(input.extraQuantity) : 0;

  let totalQuantity = quantity;
  if (extraQuantity && extraQuantity > quantity) {
    const realExtraQuantity = extraQuantity - quantity;
    totalQuantity += realExtraQuantity;
  }
  const productPrice =
    input.productPrice || input.productPrice === 0
      ? Number(input.productPrice) || 0
      : Number(input?.product?.price) || 0;

  return {
    id: input.id,
    quantity,
    extraQuantity,
    productPrice,
    totalQuantity,
    productWithoutDiscountPrice:
      input?.productWithoutDiscountPrice || productPrice,
    productDiscountName: input?.productDiscountName || '',
    productName: input?.product?.name || '',
    productReferenceCode: input?.product?.referenceCode || '',
    productId: input?.product?.id || '',
    discountData: input.discountData
      ? {
          name: input.discountData.name || '',
          price: input.discountData.price || null,
          percentage: input.discountData.percentage || null,
        }
      : null,
  };
};

export type Order = ReturnType<typeof normalizeOrder>;
