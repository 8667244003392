import { useCallback, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useReactiveVar } from '@apollo/client';
import { subscriptionStateVar } from 'apollo/reactive/subscriptionState';
import useProduct from 'apollo/hooks/product/useProduct';
import useModal from 'apollo/hooks/useModal';
import useCategories from 'apollo/hooks/category/useCategories';
import useProductActions from 'apollo/hooks/product/useProductActions';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { removeOptionalsFromArray } from 'utils/removeOptionalsFromArray';
import { formatErrors } from 'utils/errors/formatErrors';
import { CategoryType } from 'apollo/generated/globalTypes';
import { DiscountType } from 'apollo/graphql.types';
import type { ProductFormFields } from 'model/Product';
import type { Discount } from 'model/Discount';

export const useConnect = () => {
  const { productId } = useParams<{ productId: string }>();
  const { product, loading: getProductLoading } = useProduct({ id: productId });
  const { categories } = useCategories({
    filter: { type: CategoryType.PRODUCT },
  });
  const {
    updateProduct,
    deleteProduct,
    updateProductCategories,
    updateProductImages,
    assignDiscountToProduct,
    unassignDiscountToProduct,
    loading: productActionsLoading,
  } = useProductActions();
  const { enqueueSnackbar } = useSnackbar();
  const {
    openDialog,
    close: closeModal,
    openAdjustProductOptionsDialog,
    openAdjustProductQuantity,
    openAdjustProductStore,
    openProductsAdvancedMenu,
    openAdjustProductBaseWeight,
    openNewReferencedProduct,
    openStoreProductOptionsDialog,
    openMoveStoreToStock,
    openAssignDiscounts,
    openGenerateBarCodeDialog,
  } = useModal();
  const navigate = useNavigate();
  const subscription = useReactiveVar(subscriptionStateVar);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedSection, setSelectedSection] = useState('profile');

  const { initialFormValues, initialImagesValues, initialCategoriesValues } =
    useMemo(() => {
      const mappedCategories = removeOptionalsFromArray(
        product?.categories ?? [],
      ).map((c) => c.id);

      setSelectedCategories(mappedCategories);

      const productId = product?.id || '';
      let productBarCode = product?.barCode || '';

      if (productId && !productBarCode) {
        productBarCode = productId.slice(0, 8);
      }

      const initialFormValues = {
        baseWeight: product?.baseWeight || 0,
        countToMaxConsume: product?.countToMaxConsume || false,
        description: product?.description || '',
        id: productId,
        isActive: product?.isActive || false,
        showInMenu: product?.showInMenu || false,
        name: product?.name || '',
        referenceCode: product?.referenceCode || '',
        costs: product?.costs || 0,
        price: product?.price || 0,
        quantity: product?.quantity || 0,
        storeQuantity: product?.storeQuantity || 0,
        taxes: product?.taxes || ('' as unknown as number),
        barCode: productBarCode,

        // Read Only
        discount: product?.discount?.name || 'Asignar descuento',
      };

      const initialImagesValues = {
        mainImageId: product?.mainImageId || '',
        mainImageIsUploading: false,
        mainImageSize: product?.mainImageSize || ('' as unknown as number),
        mainImageUrl: product?.mainImageUrl || '',
      };

      const initialCategoriesValues = { categoryIds: mappedCategories };

      return {
        initialCategoriesValues,
        initialFormValues,
        initialImagesValues,
      };
    }, [product]);

  const handleToggleCategory = useCallback(
    async (id: string) => {
      const selected = selectedCategories.find((c) => c === id);
      if (selected) {
        setSelectedCategories((list) => list.filter((c) => c !== id));
      } else {
        setSelectedCategories((list) => [...list, id]);
      }
    },
    [selectedCategories],
  );

  const handleOnSubmit = useCallback(
    async (values: ProductFormFields) => {
      try {
        if (productId) {
          await updateProduct(productId, {
            ...values,
          });
          enqueueSnackbar('El producto se ha actualizado correctamente', {
            variant: 'success',
          });
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('product', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [enqueueSnackbar, productId, updateProduct],
  );

  const handleOnSubmitImages = useCallback(
    async (data: Partial<ProductFormFields>) => {
      try {
        if (productId) {
          await updateProductImages(productId, data);
          enqueueSnackbar('La imagen se ha actualizado correctamente', {
            variant: 'success',
          });
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('product', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [enqueueSnackbar, productId, updateProductImages],
  );

  const handleOnSubmitCategories = useCallback(async () => {
    try {
      if (productId) {
        await updateProductCategories(productId, {
          categoryIds: selectedCategories,
        });
        enqueueSnackbar('Las categorías se ha actualizado correctamente', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(formatErrors('product', e.message, 'actualizar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [enqueueSnackbar, productId, selectedCategories, updateProductCategories]);

  const handleOnDelete = useCallback(async () => {
    try {
      if (productId) {
        await deleteProduct(productId);
        closeModal();
        navigate('/products');
        enqueueSnackbar('El producto se ha eliminado correctamente', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(formatErrors('product', e.message, 'eliminar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [productId, deleteProduct, closeModal, navigate, enqueueSnackbar]);

  const handleOpenRemoveDialog = useCallback(() => {
    openDialog({
      acceptButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      description:
        'Vas a eliminar este producto, se borrarán todos sus datos y es una acción que no se puede deshacer, ¿quieres eliminarlo?',
      onAccept: handleOnDelete,
      title: 'Eliminar producto',
      variant: 'danger',
      iconName: 'products',
    });
  }, [openDialog, handleOnDelete]);

  const handleOpenNewReference = useCallback(() => {
    if (product) {
      openNewReferencedProduct({ product });
    }
  }, [product, openNewReferencedProduct]);

  const handleOpenAdjustModal = useCallback(() => {
    if (product) {
      openAdjustProductOptionsDialog({
        onAcceptAdd: () =>
          openAdjustProductQuantity({
            product,
            reloadPage: true,
            actionType: 'add',
          }),
        onAcceptRemove: () =>
          openAdjustProductQuantity({
            product,
            reloadPage: true,
            actionType: 'remove',
          }),
        onAcceptSet: () =>
          openAdjustProductQuantity({
            product,
            reloadPage: true,
            actionType: 'set',
          }),
      });
    }
  }, [openAdjustProductOptionsDialog, openAdjustProductQuantity, product]);

  const handleOpenBaseWeightModal = useCallback(() => {
    if (product) {
      openAdjustProductBaseWeight({
        product,
        reloadPage: true,
      });
    }
  }, [openAdjustProductBaseWeight, product]);

  const handleOpenStoreModal = useCallback(() => {
    if (product) {
      openStoreProductOptionsDialog({
        onAcceptAdd: () =>
          openAdjustProductStore({
            product,
            reloadPage: true,
            actionType: 'add',
          }),
        onAcceptRemove: () =>
          openAdjustProductStore({
            product,
            reloadPage: true,
            actionType: 'remove',
          }),
        onAcceptMovement: () =>
          openMoveStoreToStock({
            product,
            reloadPage: true,
          }),
      });
    }
  }, [
    openStoreProductOptionsDialog,
    openAdjustProductStore,
    openMoveStoreToStock,
    product,
  ]);

  const handleSelectedSection = useCallback((section: string) => {
    setSelectedSection(section);
  }, []);

  const handleAssignDiscount = useCallback(
    async (discounts: Discount[]) => {
      try {
        if (productId) {
          if (discounts.length && discounts[0]) {
            await assignDiscountToProduct(productId, discounts[0].id);
            enqueueSnackbar('Se ha asignado el descuento correctamente', {
              variant: 'success',
            });
          } else {
            await unassignDiscountToProduct(productId);
            enqueueSnackbar(
              'El descuento se ha eliminado del producto correctamente',
              {
                variant: 'success',
              },
            );
          }
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('product', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [
      assignDiscountToProduct,
      unassignDiscountToProduct,
      enqueueSnackbar,
      productId,
    ],
  );

  const handleOpenDiscounts = useCallback(() => {
    if (product) {
      openAssignDiscounts({
        onSubmit: handleAssignDiscount,
        oneSelection: true,
        type: DiscountType.UnitProduct,
        selectedDiscount:
          (product.discount?.id && product.discount) || undefined,
      });
    }
  }, [handleAssignDiscount, openAssignDiscounts, product]);

  const handleOpenBarCode = useCallback(() => {
    if (product?.barCode) {
      openGenerateBarCodeDialog({ value: product.barCode });
    } else {
      enqueueSnackbar('El producto no tiene un código asignado', {
        variant: 'warning',
      });
    }
  }, [openGenerateBarCodeDialog, product, enqueueSnackbar]);

  const handleOpenProductAdvancedModal = useCallback(() => {
    openProductsAdvancedMenu({
      onRemove: handleOpenRemoveDialog,
      onAdjust: handleOpenAdjustModal,
      onStoreMovement: handleOpenStoreModal,
      onCreateReference: handleOpenNewReference,
      assignDiscount: handleOpenDiscounts,
      onBaseWeightMovement: handleOpenBaseWeightModal,
      showBarCode: handleOpenBarCode,
    });
  }, [
    handleOpenDiscounts,
    handleOpenNewReference,
    handleOpenAdjustModal,
    handleOpenRemoveDialog,
    openProductsAdvancedMenu,
    handleOpenBaseWeightModal,
    handleOpenStoreModal,
    handleOpenBarCode,
  ]);

  return {
    categories,
    handleOnSubmit,
    handleOnSubmitCategories,
    handleOnSubmitImages,
    handleOpenAdjustModal,
    handleOpenRemoveDialog,
    handleSelectedSection,
    handleToggleCategory,
    initialCategoriesValues,
    initialFormValues,
    initialImagesValues,
    isLoading: getProductLoading || productActionsLoading,
    selectedSection,
    subscription,
    handleOpenProductAdvancedModal,
    productId,
    handleOpenDiscounts,
  };
};
