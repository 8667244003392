import { QueryHookOptions, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_CASHIER_BY_PERIOD } from 'apollo/queries/cashiers';
import { normalizeCashierByPeriod } from 'model/Cashier';
import type {
  CashierByPeriodQuery,
  CashierByPeriodQueryVariables,
} from 'apollo/graphql.types';

const useCashierByPeriod = ({
  filters,
  fetchPolicy = 'no-cache',
}: {
  filters?: { from?: Date; to?: Date };
  fetchPolicy?: QueryHookOptions<CashierByPeriodQuery>['fetchPolicy'];
} = {}) => {
  const { data, loading, refetch, error } = useQuery<
    CashierByPeriodQuery,
    CashierByPeriodQueryVariables
  >(GET_CASHIER_BY_PERIOD, {
    fetchPolicy,
    variables: {
      filters: {
        fromDate: filters?.from || null,
        toDate: filters?.to || null,
      },
    },
  });

  const cashier = useMemo(
    () =>
      data?.cashierByPeriod
        ? normalizeCashierByPeriod(data.cashierByPeriod)
        : undefined,
    [data],
  );

  return {
    loading,
    cashier,
    refetch,
    error,
  };
};

export default useCashierByPeriod;
