import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'notistack';
import useModal from 'apollo/hooks/useModal';
import useProducts from 'apollo/hooks/product/useProducts';
import useMostUsedCategories from 'apollo/hooks/category/useMostUsedCategories';
import { permissionsStateVar } from 'apollo/reactive/permissionsState';
import type { Product } from 'model/Product';
import type { ProductOrderBy } from 'apollo/graphql.types';

const useConnect = () => {
  const {
    products,
    loading: productsLoading,
    refetch: refetchProducts,
  } = useProducts();
  const { mostUsedCategories } = useMostUsedCategories();
  const { openAdjustProductQuantity, openNewProduct, openMoveStoreToStock } =
    useModal();
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const { enqueueSnackbar } = useSnackbar();
  const [orderBy, setOrderBy] = useState<ProductOrderBy | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [filter, setFilter] = useState('');
  const [currentFilter, setCurrentFilter] = useState('');

  useEffect(() => {
    setIsRefetching(true);
    try {
      refetchProducts({
        filters: {
          keyword: filter,
          categories: selectedCategories,
          orderBy: orderBy as any,
        },
      });
      setIsRefetching(false);
    } catch (error) {
      setIsRefetching(false);
      enqueueSnackbar('No se puede realizar la búsqueda', { variant: 'error' });
    }
  }, [enqueueSnackbar, filter, refetchProducts, selectedCategories, orderBy]);

  const handleOpenAdjustProduct = useCallback(
    (product: Product, actionType: 'set' | 'add' | 'remove' | 'store') => {
      if (employeePermissions.allowAdjustProducts) {
        if (actionType === 'store') {
          openMoveStoreToStock({ product });
        } else {
          openAdjustProductQuantity({ product, actionType });
        }
      }
    },
    [employeePermissions, openAdjustProductQuantity, openMoveStoreToStock],
  );

  const isSelectedCategory = useCallback(
    (name: string) =>
      !!selectedCategories.find((category) => category === name),
    [selectedCategories],
  );

  const handleChangeOrder = useCallback((order: ProductOrderBy) => {
    setOrderBy(order);
  }, []);

  const handleSearch = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const newSearch = e.target.value;
    if (!newSearch) {
      setFilter(newSearch);
    } else {
      setCurrentFilter(newSearch);
    }
  }, []);

  const handleForceSearch = useCallback(async () => {
    setFilter(currentFilter);
  }, [currentFilter]);

  const handleToggleCategory = useCallback(
    async (name: string) => {
      const exists = isSelectedCategory(name);
      if (exists) {
        setSelectedCategories((prev) =>
          prev.filter((category) => category !== name),
        );
      } else {
        setSelectedCategories((prev) => [...prev, name]);
      }
    },
    [isSelectedCategory],
  );

  return {
    employeePermissions,
    handleOpenAdjustProduct,
    isLoading: productsLoading,
    products,
    handleOpenNewProductModal: openNewProduct,
    handleChangeOrder,
    orderBy,
    mostUsedCategories,
    isSelectedCategory,
    isRefetching,
    filter,
    handleSearch,
    handleForceSearch,
    handleToggleCategory,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
