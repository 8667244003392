import {
  object as yupObject,
  string as yupString,
  number as yupNumber,
  boolean as yupBoolean,
} from 'yup';

const requiredMsg = 'Este campo es requerido.';

export const validationSchema = yupObject({
  name: yupString().required(requiredMsg),
  description: yupString(),
  price: yupNumber().required(requiredMsg),
  quantity: yupNumber().required(requiredMsg),
  baseWeight: yupNumber(),
  isActive: yupBoolean(),
  countToMaxConsume: yupBoolean(),
  barCode: yupString()
    .min(5, 'El código debe tener mínimo 5 caracteres')
    .max(12, 'El código debe tener máximo 12 caracteres'),
});
