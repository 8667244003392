import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { organizationConfigVar } from 'apollo/reactive';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';
import useMostUsedCategories from 'apollo/hooks/category/useMostUsedCategories';
import useProducts from 'apollo/hooks/product/useProducts';
import type { ProductOrderBy } from 'apollo/graphql.types';
import type { Product } from 'model/Product';

const useConnect = () => {
  const { close, openNewOrder, type, productPayload } = useModal();
  const { mostUsedCategories } = useMostUsedCategories();
  const { onSelect, filters, onSubmitPurchase } = productPayload || {};
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const {
    products,
    loading: getProductsLoading,
    refetch,
  } = useProducts({ filters });
  const { enqueueSnackbar } = useSnackbar();
  const [isRefetching, setIsRefetching] = useState(false);
  const [filter, setFilter] = useState('');
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState<ProductOrderBy | null>(null);

  useEffect(() => {
    setIsRefetching(true);
    try {
      refetch({
        filters: {
          keyword: filter,
          categories: selectedCategories,
          orderBy: orderBy as any,
          ...filters,
        },
      });
      setIsRefetching(false);
    } catch (error) {
      setIsRefetching(false);
      enqueueSnackbar('Unable to load search', { variant: 'error' });
    }
  }, [enqueueSnackbar, orderBy, filter, filters, refetch, selectedCategories]);

  const handleSearch = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const newSearch = e.target.value;
    setFilter(newSearch);
  }, []);

  const handleClose = useCallback(async () => {
    close();
    openNewOrder({ onSubmitPurchase });
    setIsRefetching(false);
    setFilter('');
    setSelectedCategories([]);
    await refetch();
  }, [close, openNewOrder, refetch, onSubmitPurchase]);

  const handleSelect = useCallback(
    async (product: Product) => {
      if (onSelect) {
        onSelect(product);
      }
      await handleClose();
    },
    [onSelect, handleClose],
  );

  const isSelectedCategory = useCallback(
    (name: string) =>
      !!selectedCategories.find((category) => category === name),
    [selectedCategories],
  );

  const handleToggleCategory = useCallback(
    async (name: string) => {
      const exists = isSelectedCategory(name);
      if (exists) {
        setSelectedCategories((prev) =>
          prev.filter((category) => category !== name),
        );
      } else {
        setSelectedCategories((prev) => [...prev, name]);
      }
    },
    [isSelectedCategory],
  );

  const handleChangeOrder = useCallback((order: ProductOrderBy) => {
    setOrderBy(order);
  }, []);

  return {
    handleSelect,
    handleClose,
    handleSearch,
    products,
    isOpen: type === ModalType.PRODUCTS,
    isLoading: getProductsLoading,
    isRefetching,
    isSelectedCategory,
    filter,
    mostUsedCategories,
    handleToggleCategory,
    handleChangeOrder,
    orderBy,
    productsDisplayType: organizationConfig.productsListType,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
