import { FC, memo } from 'react';

import type { Props } from './types';

const Barcode: FC<Props> = ({ size = 7, ...rest }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    {...rest}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeWidth="3"
      d="m37.5 37.53 4.5-.03v-27l-4.5.03m-27-.03-4.5.03v27l4.5-.03M36 18v12m-6-15v18m-6-16.5v15M18 15v18m-6-15v12"
    />
  </svg>
);

export default memo(Barcode);
