import { gql } from '@apollo/client';
import { CATEGORY_FRAGMENT } from 'apollo/fragments/Category';

export const GET_CATEGORIES = gql`
  query GetCategories($filter: CategoryFilterDto) {
    categories(filter: $filter) {
      ...CategoryData
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const GET_CATEGORY = gql`
  query GetCategory($id: ID!) {
    category(id: $id) {
      id
      name
      description
      showInCashier
      showInMenu
      type
      updatedAt
      createdAt
      deletedAt
      products {
        ... on Product {
          id
          name
        }
      }
    }
  }
`;

export const GET_MOST_USED_CATEGORIES = gql`
  query GetMostUsedCategories {
    mostUsedCategories {
      ...CategoryData
    }
  }
  ${CATEGORY_FRAGMENT}
`;
