import { FC, memo } from 'react';
import { CSVLink } from 'react-csv';
import { useMediaQuery } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { organizationConfigVar } from 'apollo/reactive';
import { from } from 'styles/responsive';
import Loader from 'components/Loader';
import DateTimeFilter from 'components/DateTimeFilter';
import { formatDate2 } from 'utils/dates/formatDates';
import TableHead from './TableHead';
import Row from './Row';
import TotalRow from './TotalRow';
import useConnect from './connect';
import {
  Container,
  Header,
  BackLink,
  HeaderInfo,
  HeaderName,
  DownloadButton,
  Main,
  ListContainer,
  FileIcon,
  Filters,
  CategoriesFilter,
  CategoryFilter,
  CategoryName,
  DateTimeFilterContainer,
} from './styles';

const CashierProducts: FC = () => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const {
    isLoading,
    products,
    csvInfo,
    employeePermissions,
    filterDates,
    mostUsedCategories,
    isSelectedCategory,
    handleToggleCategory,
    handleOnSearchDates,
  } = useConnect();

  const hasMostUsedCategories = mostUsedCategories.length > 0;

  return (
    <Container>
      <Header>
        <BackLink />
        <HeaderInfo>
          <HeaderName>
            Movimientos de stock{' '}
            {formatDate2(
              filterDates.from ? new Date(filterDates.from) : new Date(),
            )}{' '}
            -{' '}
            {formatDate2(
              filterDates.to ? new Date(filterDates.to) : new Date(),
            )}
          </HeaderName>
        </HeaderInfo>
        {!isLoading && employeePermissions.allowDownloadCashierData && (
          <>
            {fromTabletPortrait ? (
              <DownloadButton>
                <CSVLink
                  data={csvInfo.data}
                  headers={csvInfo.headers}
                  filename={csvInfo.filename}
                  target="_blank"
                >
                  Descargar
                </CSVLink>
              </DownloadButton>
            ) : (
              <CSVLink
                data={csvInfo.data}
                headers={csvInfo.headers}
                filename={csvInfo.filename}
                target="_blank"
              >
                <FileIcon />
              </CSVLink>
            )}
          </>
        )}
      </Header>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Filters>
            <DateTimeFilterContainer>
              <DateTimeFilter
                fromDate={filterDates.from}
                toDate={filterDates.to}
                onSearch={handleOnSearchDates}
              />
            </DateTimeFilterContainer>
            <CategoriesFilter>
              {hasMostUsedCategories &&
                mostUsedCategories.map((category) => (
                  <CategoryFilter
                    key={category.id}
                    $selected={isSelectedCategory(category.name)}
                    onClick={() => handleToggleCategory(category.name)}
                  >
                    <CategoryName>{category.name}</CategoryName>
                  </CategoryFilter>
                ))}
            </CategoriesFilter>
          </Filters>
          <Main>
            <ListContainer>
              <TableHead />
              <TotalRow item={products?.stats} />
              {products?.items.map((product) => (
                <Row
                  key={product.id}
                  product={product}
                  activateProductReference={
                    organizationConfig?.activateProductReference
                  }
                />
              ))}
            </ListContainer>
          </Main>
        </>
      )}
    </Container>
  );
};

export default memo(CashierProducts);
