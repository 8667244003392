import { FC, memo } from 'react';
import { Formik } from 'formik';
import { useReactiveVar } from '@apollo/client';
import { permissionsStateVar } from 'apollo/reactive';
import Select from 'components/Inputs/Select';
import Switch from 'components/Inputs/Switch';
import { categoryTypeOptions } from 'model/Category';
import { Container, Row, SubmitButton, TextField } from './styles';
import { validationSchema } from './constants';
import type { Props } from './types';

const ProfileForm: FC<Props> = ({
  className,
  id = 'new',
  onSubmit,
  initialValues,
}) => {
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const formId = `category_${id}`;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isValid: isFormValid }) => (
        <Container className={className}>
          <Row>
            <TextField name="name" label="Nombre" id={`${formId}_name`} />
            <Select
              name="type"
              label="Tipo de categoría"
              id={`${formId}_type`}
              options={categoryTypeOptions}
            />
          </Row>
          <TextField
            name="description"
            label="Descripción"
            isMultiline
            rows={4}
            id={`${formId}_description`}
          />
          <Row>
            <Switch
              id={`${formId}_showInCashier`}
              label="Mostrar en caja"
              name="showInCashier"
            />
            <Switch
              id={`${formId}_showInMenu`}
              label="Mostrar en productos"
              name="showInMenu"
            />
          </Row>

          {employeePermissions.allowEditCategories && (
            <SubmitButton type="submit" disabled={!isFormValid}>
              Guardar
            </SubmitButton>
          )}
        </Container>
      )}
    </Formik>
  );
};

export default memo(ProfileForm);
