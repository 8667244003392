import { apolloClient } from 'services/api';
import { GET_PRODUCTS } from 'apollo/queries';
import { normalizeProduct, Product } from 'model/Product';
import type {
  GetProductsQuery,
  GetProductsQueryVariables,
} from 'apollo/graphql.types';

const getProduct = async (value: Product['barCode'] | Product['id']) => {
  const { data } = await apolloClient.query<
    GetProductsQuery,
    GetProductsQueryVariables
  >({
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    query: GET_PRODUCTS,
  });

  const products = data?.products;
  if (!products) {
    return undefined;
  }

  const selectedProduct = products.find(
    (partner) => partner.barCode === value || partner.id === value,
  );

  if (!selectedProduct) {
    return undefined;
  }

  return normalizeProduct(selectedProduct);
};

export default getProduct;
