import styled, { css } from 'styled-components';
import { SectionHeader } from 'components/Layout';
import { from } from 'styles/responsive';
import DefaultBackLink from 'components/BackLinkV2';
import DefaultButton from 'components/Button';

export const Header = styled(SectionHeader)`
  align-items: center;

  ${from.tabletLandscape`
    margin-bottom: 3rem;
  `}
`;

export const BackLink = styled(DefaultBackLink)``;

export const AvatarContainer = styled.div`
  margin: 0 1rem;
`;

export const HeaderButtons = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-left: auto;

  > :not(:last-child) {
    margin-right: 1rem !important;
  }
  > * {
    flex: 0 0 8rem;

    ${from.desktop`
      flex: 0 1 12.5rem;
    `}

    ${from.tabletPortrait`
      justify-content: flex-start;
    `}
  }
`;

export const HeaderInfo = styled.div`
  flex: 1 0 auto;
  margin-left: 1rem;
`;

export const HeaderName = styled.p<{ $isActive?: boolean }>`
  display: flex;
  align-items: start;
  flex: 1 0 auto;
  text-transform: capitalize;

  ${({ $isActive, theme }) =>
    !$isActive &&
    css`
      color: ${theme.colors.danger};
    `}
`;

export const HeaderId = styled.p`
  color: ${({ theme }) => theme.colors.grey500};
  cursor: pointer;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin: 0.5rem 0 0;
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

export const SecondaryButton = styled(DefaultButton).attrs(() => ({
  variant: 'light_black',
}))`
  &&& {
    max-width: 10%;
  }
`;
