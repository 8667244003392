import styled from 'styled-components';
import ArrowRight from 'components/Icon/Icons/ArrowRight';

export const Container = styled.div`
  cursor: pointer;
`;

export const Chevron = styled(ArrowRight)`
  transform: rotate(180deg);
`;
