import { FC, memo } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import useLogic from './logic';
import type { Props } from './types';
import {
  Block,
  BlockProduct,
  BlockProducts,
  Blocks,
  BlockTitle,
  Price,
  ProductTitle,
  OrganizationLogo,
  Logo,
  ProductReference,
  ProductPriceContent,
  OriginalPrice,
} from './styles';

const StaticView: FC<Props> = ({ menuData, useLogoBackground }) => {
  const { categories } = useLogic(menuData);
  const hasLogo =
    !useLogoBackground && menuData?.showLogo && menuData.organizationLogo;

  return (
    <>
      {hasLogo && (
        <OrganizationLogo>
          <Logo src={menuData.organizationLogo || ''} />
        </OrganizationLogo>
      )}
      <Blocks>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter="8px">
            {categories.map((cat) => (
              <Block
                $color={menuData.imageBorderColor}
                $useLogoBackground={useLogoBackground}
                $backgroundColor={menuData?.backgroundColor}
              >
                <BlockTitle $color={menuData.categoriesColor}>
                  {cat.catName}
                </BlockTitle>
                <BlockProducts>
                  {cat.products.map((product) => (
                    <BlockProduct>
                      <ProductTitle $color={menuData.productTitleColor}>
                        {product.title}
                        {product.referenceCode && (
                          <ProductReference>
                            #{product.referenceCode}
                          </ProductReference>
                        )}
                      </ProductTitle>{' '}
                      {menuData.showProductPrice && (
                        <>
                          {menuData?.showProductDiscount &&
                          product.hasDiscount ? (
                            <ProductPriceContent>
                              <Price
                                $color={menuData.productPriceColor}
                                showIcon={menuData.showPriceSimbol}
                              >
                                {product.priceWithDiscount}
                              </Price>{' '}
                              <OriginalPrice>
                                <Price showIcon={menuData.showPriceSimbol}>
                                  {product.price}
                                </Price>
                              </OriginalPrice>
                            </ProductPriceContent>
                          ) : (
                            <ProductPriceContent>
                              <Price
                                $color={menuData.productPriceColor}
                                showIcon={menuData.showPriceSimbol}
                              >
                                {product.price}
                              </Price>
                            </ProductPriceContent>
                          )}
                        </>
                      )}
                    </BlockProduct>
                  ))}
                </BlockProducts>
              </Block>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </Blocks>
    </>
  );
};

export default memo(StaticView);
