import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  permissionsStateVar,
  subscriptionStateVar,
  userRoleVar,
} from 'apollo/reactive';
import useModal from 'apollo/hooks/useModal';
import { ModalType } from 'apollo/reactive/modal';

const useConnect = () => {
  const { close, partnerAdvancedMenuPayload, type } = useModal();
  const subscription = useReactiveVar(subscriptionStateVar);
  const userRole = useReactiveVar(userRoleVar);
  const employeePermissions = useReactiveVar(permissionsStateVar);

  const {
    removePartner,
    convertToPartnerToEmployee,
    loadCredits,
    assignDiscounts,
    generateContractFile,
    showBarCode,
    isEmployee,
    openOrder,
  } = partnerAdvancedMenuPayload || {};

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleRemovePartner = useCallback(() => {
    if (removePartner) {
      removePartner();
    }
  }, [removePartner]);

  const handleOpenLoadCredits = useCallback(
    (action: 'retire' | 'added') => {
      if (loadCredits) {
        loadCredits(action);
      }
    },
    [loadCredits],
  );

  const handleOpenBarCode = useCallback(() => {
    if (showBarCode) {
      showBarCode();
    }
  }, [showBarCode]);

  const handleOpenGenerateFiles = useCallback(() => {
    if (generateContractFile) {
      generateContractFile();
    }
  }, [generateContractFile]);

  const handleOpenOrder = useCallback(() => {
    if (openOrder) {
      openOrder();
    }
  }, [openOrder]);

  const handleOpenAssignDiscounts = useCallback(() => {
    if (assignDiscounts) {
      assignDiscounts();
    }
  }, [assignDiscounts]);

  const handleOpenConvertToEmployee = useCallback(() => {
    if (convertToPartnerToEmployee) {
      convertToPartnerToEmployee();
    }
  }, [convertToPartnerToEmployee]);

  return {
    handleOpenOrder,
    handleClose,
    handleOpenAssignDiscounts,
    handleOpenGenerateFiles,
    handleOpenLoadCredits,
    handleRemovePartner,
    handleOpenConvertToEmployee,
    handleOpenBarCode,
    isOpen: type === ModalType.PARTNER_ADVANCED_MENU,
    subscription,
    userRole,
    isEmployee,
    employeePermissions,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
