import { FC, memo } from 'react';
import { Formik } from 'formik';
import { useReactiveVar } from '@apollo/client';
import { permissionsStateVar, organizationConfigVar } from 'apollo/reactive';
import Switch from 'components/Inputs/Switch';
import { Container, Row, SubmitButton, TextField, CheckBoxRow } from './styles';
import type { Props } from './types';
import { validationSchema } from './constants';

const ProfileForm: FC<Props> = ({
  className,
  id = 'new',
  onSubmit,
  initialValues,
  onClickDiscount,
}) => {
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const formId = `product_${id}`;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isValid: isFormValid }) => (
        <Container className={className}>
          <Row>
            <TextField name="name" label="Nombre *" id={`${formId}_name`} />
            <TextField
              name="price"
              type="number"
              label="Precio *"
              id={`${formId}_price`}
            />
          </Row>
          <Row>
            <TextField
              readOnly
              name="quantity"
              type="number"
              label="Cantidad en Stock *"
              id={`${formId}_quantity`}
            />
            <TextField
              readOnly
              placeholder="800"
              type="number"
              name="storeQuantity"
              label="Cantidad en Almacén"
              id={`${formId}_storeQuantity`}
            />
          </Row>
          <Row>
            <TextField
              readOnly
              name="baseWeight"
              type="number"
              label="Cantidad base"
              id={`${formId}_baseWeight`}
            />
            <TextField
              name="costs"
              type="number"
              label="Coste"
              id={`${formId}_costs`}
            />
          </Row>

          <Row>
            <TextField
              readOnly
              name="totalBase"
              type="number"
              label="Cantidad total"
              value={Number(
                (
                  (initialValues?.baseWeight || 0) +
                  (initialValues?.quantity || 0)
                ).toFixed(2),
              )}
              id={`${formId}_totalBase`}
            />
            {organizationConfig.activateProductReference && (
              <TextField
                name="referenceCode"
                label="Referencia"
                id={`${formId}_referenceCode`}
              />
            )}
          </Row>

          <Row>
            <TextField
              name="barCode"
              label="Código de barra"
              id={`${formId}_barCode`}
            />
            <TextField
              readOnly
              name="discount"
              type="string"
              label="Descuento"
              icon="arrowRight"
              iconSize={8}
              onClick={onClickDiscount}
              id={`${formId}_discount`}
            />
          </Row>

          <TextField
            name="description"
            label="Descripción"
            isMultiline
            rows={4}
            id={`${formId}_description`}
          />
          <CheckBoxRow>
            <Switch id={`${formId}_isActive`} label="Activo" name="isActive" />
            <Switch
              id={`${formId}_countToMaxConsume`}
              label="Añadir al consumo maximo"
              name="countToMaxConsume"
            />
          </CheckBoxRow>
          <CheckBoxRow>
            <Switch
              id={`${formId}_showInMenu`}
              label="Mostrar en el menú"
              name="showInMenu"
            />
          </CheckBoxRow>

          {employeePermissions.allowEditProducts && (
            <SubmitButton type="submit" disabled={!isFormValid}>
              Guardar
            </SubmitButton>
          )}
        </Container>
      )}
    </Formik>
  );
};

export default memo(ProfileForm);
