import parseISO from 'date-fns/parseISO';
import type { ProductData } from 'apollo/generated/ProductData';
import type { OrderData_product } from 'apollo/generated/OrderData';
import {
  ProductDataFragment,
  ProductQuantityHistoryDataFragment,
  ProductStatisticsDataFragment,
  QuantityMovementType,
} from 'apollo/graphql.types';
import type {
  GetProductTransactions_productTransactions,
  GetProductTransactions_productTransactions_transactions,
} from 'apollo/generated/GetProductTransactions';
import { removeOptionalsFromArray } from 'utils/removeOptionalsFromArray';
import { normalizeCategory } from './Category';
import { Discount, normalizeDiscount } from './Discount';

const getInitials = (name: string) => {
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export type ProductFormFields = Readonly<{
  id?: string;
  baseWeight?: number;
  categoryIds?: string[];
  costs?: number;
  countToMaxConsume: boolean;
  description: string;
  referenceCode?: string;
  barCode?: string;
  isActive: boolean;
  showInMenu: boolean;
  name: string;
  price: number;
  quantity?: number;
  storeQuantity?: number;
  taxes: number;

  // Images
  mainImageId?: string;
  mainImageIsUploading?: boolean;
  mainImageUrl?: string;
  mainImageSize?: number;
}>;

export type ProductReferenceFormFields = Readonly<{
  baseWeight?: number;
  costs?: number;
  price: number;
  quantity?: number;
  referenceCode?: string;
}>;

export type ProductAdjustQuantityFormFields = Readonly<{
  baseWeight?: number;
  name?: string;
  newQuantity?: number;
  quantity?: number;
  currentProductStock?: number;
  note?: string;
  movementType: QuantityMovementType;
}>;

export type ProductOrderItem = {
  id: string;
  name: string;
  referenceCode: string;
  price: number;
  originalPrice: number;
  amount: number;
  extraAmount: number;
  productPrice: number;
  productQuantity: number;
  countToMaxConsume: boolean;
  discount?: Discount | null;
  selectedProductDiscount: Discount | null;
  isDiscountSet?: boolean;
};

export const normalizeProduct = (
  input: ProductData | OrderData_product | ProductDataFragment,
) => {
  return {
    // ID
    id: input.id || '',

    // FIELDS
    abbreviation: getInitials(input.name) || '',
    baseWeight: input?.baseWeight || 0,
    // @ts-ignore
    categories: removeOptionalsFromArray(input?.categories ?? []).map(
      // @ts-ignore
      (occurrence) => normalizeCategory(occurrence),
    ),
    countToMaxConsume: input.countToMaxConsume || false,
    description: input.description || '',
    isActive: input.isActive,
    // @ts-ignore
    showInMenu: input.showInMenu,
    // @ts-ignore
    storeQuantity: input.storeQuantity,
    name: input.name || '',
    referenceCode:
      'referenceCode' in input && input.referenceCode
        ? input.referenceCode
        : '',
    barCode: 'barCode' in input && input.barCode ? input.barCode : '',
    costs: input?.costs ? Number(input.costs) : 0,
    price: input?.price ? Number(input.price) : 0,
    quantity: input?.quantity ? Number(input.quantity.toFixed(2)) : 0,
    taxes: input?.taxes ? Number(input.taxes) : 0,
    // @ts-ignore
    discount:
      'discount' in input && input.discount
        ? normalizeDiscount(input.discount)
        : null,

    // Image Fields
    mainImageId: input.mainImage?.id || '',
    mainImageSize: input.mainImage?.file_size || 0,
    mainImageUrl: input.mainImage?.url || '',
  };
};

export type Product = ReturnType<typeof normalizeProduct>;

const normalizeTransaction = (
  input: GetProductTransactions_productTransactions_transactions,
) => ({
  partner: {
    id: input.partner.id,
    memberNum: input.partner.memberNum,
    fullName: `${input.partner.user.firstName} ${input.partner.user.lastName}`,
  },
  employee: {
    id: input.employee.id,
    fullName: `${input.employee.user.firstName} ${input.employee.user.lastName}`,
  },
  total: input.total || 0.0,
  // @ts-ignore
  quantity: input.quantity || 0.0,
  // @ts-ignore
  extraQuantity: input.extraQuantity || 0.0,
  date: input.date ? parseISO(input.date).getTime() : new Date().getTime(),
  id: input.purchaseId || '',
});
export type ProductTransaction = ReturnType<typeof normalizeTransaction>;

export const normalizeProductTransaction = (
  input: GetProductTransactions_productTransactions,
) => ({
  product: input.product
    ? {
        id: input.product.id,
        name: input.product.name,
        // @ts-ignore
        referenceCode: input.product.referenceCode || '',
      }
    : null,
  transactions: removeOptionalsFromArray(input?.transactions ?? [])
    .map((occurrence) => normalizeTransaction(occurrence))
    .sort((a, b) => b.date - a.date),
});
export type ProductTransactions = ReturnType<
  typeof normalizeProductTransaction
>;

export const normalizeProductQuantityHistory = (
  input: ProductQuantityHistoryDataFragment,
) => {
  const currentBaseWeight = input?.currentBaseWeight || 0;
  const currentQuantity = input?.currentQuantity || 0;
  const lastBaseWeight = input?.lastBaseWeight || 0;
  const lastQuantity = input?.lastQuantity || 0;

  const lastTotalQuantity = lastQuantity + lastBaseWeight;
  const currentTotalQuantity = currentQuantity + currentBaseWeight;

  return {
    id: input?.id || '',
    // FIELDS
    createdAt: input?.createdAt
      ? new Date(input.createdAt).getTime()
      : new Date().getTime(),
    currentBaseWeight,
    currentQuantity,
    lastBaseWeight,
    lastQuantity,
    note: input?.note || '',
    movementType: input?.movementType || QuantityMovementType.Stock,
    adjustDifference: currentTotalQuantity - lastTotalQuantity,
    lastStock: input?.lastStock || 0,
    currentStock: input?.currentStock || 0,
    editedBy: {
      id: input?.editedBy?.id || '',
      firstName: input?.editedBy?.user?.firstName || '',
      lastName: input?.editedBy?.user?.lastName || '',
    },
    product: {
      name: input?.product?.name || '',
      storeQuantity: input?.product?.storeQuantity || 0,
      imageUrl: input?.product?.mainImage?.url || '',
    },
  };
};

export type ProductQuantityHistory = ReturnType<
  typeof normalizeProductQuantityHistory
>;

export const normalizeProductStatistics = (
  input?: ProductStatisticsDataFragment,
) => {
  return {
    id: input?.id || '',
    referenceCode: input?.referenceCode || '',
    countToMaxConsume: input?.countToMaxConsume || false,
    consumedExtraQuantity:
      (input?.consumedExtraQuantity &&
        Number(input?.consumedExtraQuantity.toFixed(2))) ||
      0,
    consumedQuantity:
      (input?.consumedQuantity && Number(input?.consumedQuantity.toFixed(2))) ||
      0,
    totalCosts:
      (input?.totalCosts && Number(input?.totalCosts.toFixed(2))) || 0,
    totalPurchases:
      (input?.totalPurchases && Number(input?.totalPurchases.toFixed(2))) || 0,
    totalOrders:
      (input?.totalOrders && Number(input?.totalOrders.toFixed(2))) || 0,
  };
};

export const normalizeProductByName = (input: {
  name: string;
  products: ProductStatisticsDataFragment[];
}) => {
  return {
    name: input.name || '',
    products: removeOptionalsFromArray(input.products || []).map((occurrence) =>
      normalizeProductStatistics(occurrence),
    ),
  };
};
