import { apolloClient } from 'services/api';
import { GET_PARTNERS_TO_LIST } from 'apollo/queries/partners';
import { normalizePartner, Partner } from 'model/Partner';
import type {
  GetPartners,
  GetPartnersVariables,
} from 'apollo/generated/GetPartners';

const getPartner = async (
  value: Partner['memberNum'] | Partner['id'] | Partner['rfidCode'],
) => {
  const { data } = await apolloClient.query<GetPartners, GetPartnersVariables>({
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    query: GET_PARTNERS_TO_LIST,
  });

  const partners = data?.partners;
  if (!partners) {
    return undefined;
  }

  const selectedPartner = partners.find(
    (partner) =>
      partner.memberNum === value ||
      partner.id === value ||
      // @ts-ignore
      partner.rfidCode === value,
  );

  if (!selectedPartner) {
    return undefined;
  }

  return normalizePartner(selectedPartner);
};

export default getPartner;
