import type { PartnerData } from 'apollo/generated/PartnerData';
import type { PartnerListData } from 'apollo/generated/PartnerListData';
import type { PartnerUsage, UserGender } from 'apollo/generated/globalTypes';
import type {
  PartnerListDataFragment,
  PartnerUsage as NewPartnerUsage,
  UserGender as NewUserGender,
} from 'apollo/graphql.types';
import { UserRole } from 'apollo/generated/globalTypes';
import type { PartnerNoteData } from 'apollo/generated/PartnerNoteData';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import { Quota, QuotaType } from 'model/Quota';
import { DiscountType, PartnerConsumptionStats } from 'apollo/graphql.types';
import { normalizeBaseUser } from './User';

export type PartnerFormFields = {
  id?: string;
  address: string;
  birthDate?: Date | null;
  credits: number;
  document: string;
  email: string;
  firstName: string;
  gender: UserGender;
  lastName: string;
  maxConsumeMonth: number;
  memberNum?: string;
  phoneNumber: string;
  rfidCode?: string;
  quotaId?: Quota['id'];
  usage: PartnerUsage;
  // presenceOrganizationFrom?: Date | null;

  // Host Member Data
  hostMemberNum?: string;

  // Images
  avatarImageUrl?: string;

  // Read Only
  monthConsumePercentage?: number;
  quotaName?: string;
  monthConsume?: string;
};

export type PartnerFormFieldsFromQR = {
  address: string;
  birthDate?: Date | null;
  document: string;
  email: string;
  firstName: string;
  gender: NewUserGender;
  lastName: string;
  phoneNumber: string;
  usage: NewPartnerUsage;
};

export type PartnerQuotaFormFields = Readonly<{
  id?: string;
  value: string;
  name: string;
  price: number;
  nextPayment: string;
  assignedDate: string;
}>;

export type PartnerNoteFormFields = Readonly<{
  id?: string;
  description: string;
  showNotification?: boolean;
}>;

export const normalizePartnerNote = (input: PartnerNoteData) => ({
  id: input?.id || '',
  description: input?.description || '',
  showNotification: input?.showNotification || false,
  addedByName: input?.addedByName || '',
  lastEditionByName: input?.lastEditionByName || '',
  createdAt: input?.createdAt
    ? parseISO(input.createdAt).getTime()
    : new Date(),
  updatedAt: input?.updatedAt
    ? parseISO(input.updatedAt).getTime()
    : new Date(),
});
export type PartnerNote = ReturnType<typeof normalizePartnerNote>;

export const normalizePartnerConsumptionsStats = (
  input: PartnerConsumptionStats,
) => {
  const numPurchasesByEntriesRatio = input.numPurchasesByEntriesRatio || 0;
  const totalPurchasesPriceByEntriesRatio =
    input.totalPurchasesPriceByEntriesRatio || 0;

  let partnerNumOfPurchaseCategory = '';
  let partnerTotalPurchasesPriceCategory = '';

  if (numPurchasesByEntriesRatio < 0.5) {
    partnerNumOfPurchaseCategory = 'No Consumista';
  } else if (
    numPurchasesByEntriesRatio >= 0.5 &&
    numPurchasesByEntriesRatio < 1
  ) {
    partnerNumOfPurchaseCategory = 'Ocasional';
  } else if (
    numPurchasesByEntriesRatio >= 1 &&
    numPurchasesByEntriesRatio < 2
  ) {
    partnerNumOfPurchaseCategory = 'Moderado';
  } else {
    partnerNumOfPurchaseCategory = 'Consumista';
  }

  if (totalPurchasesPriceByEntriesRatio < 5) {
    partnerTotalPurchasesPriceCategory = 'Básico';
  } else if (
    totalPurchasesPriceByEntriesRatio >= 5 &&
    totalPurchasesPriceByEntriesRatio < 15
  ) {
    partnerTotalPurchasesPriceCategory = 'Económico';
  } else if (
    totalPurchasesPriceByEntriesRatio >= 15 &&
    totalPurchasesPriceByEntriesRatio < 50
  ) {
    partnerTotalPurchasesPriceCategory = 'Destacado';
  } else {
    partnerTotalPurchasesPriceCategory = 'Élite';
  }

  return {
    entries: input?.entries || 0,
    purchases: input?.purchases || 0,
    totalPriceOfPurchases: input?.totalPriceOfPurchases || 0,
    partnerNumOfPurchaseCategory,
    partnerTotalPurchasesPriceCategory,
  };
};
export type PartnerConsumptionsStats = ReturnType<
  typeof normalizePartnerConsumptionsStats
>;

export const normalizePartner = (input: PartnerData) => {
  const nextPayment = input.partnerQuota?.nextPayment
    ? format(parseISO(input.partnerQuota.nextPayment), 'dd-MM-yyyy')
    : '';

  const assignedDate = input.partnerQuota?.createdAt
    ? format(parseISO(input.partnerQuota.createdAt), 'dd-MM-yyyy')
    : '';

  let isSubscribed = false;
  if (nextPayment) {
    isSubscribed = !isBefore(new Date(nextPayment), new Date());
  }

  return {
    // Primary key
    id: input.id || '',

    // Partner fields
    isEmployee: (input.user && input.user.role === UserRole.EMPLOYEE) || false,
    credits: input.credits ? Number(input.credits.toFixed(2)) : 0,
    discounts:
      input?.discounts && input?.discounts.length > 0
        ? input.discounts.map((discount) => ({
            id: discount.id || '',
            name: discount.name || '',
            type: discount.type
              ? (discount.type as unknown as DiscountType)
              : DiscountType.Product,
            price: discount.price || 0,
            percentage: discount.percentage || 0,
          }))
        : [],
    hostMemberNum: input?.hostMemberNum || '0',
    hostMember: {
      id: input?.hostMember?.id || '',
      hostMemberNum: input?.hostMember?.hostMemberNum || '0',
      memberNum: input?.hostMember?.memberNum || '0',
      createdAt: input?.hostMember?.createdAt
        ? new Date(input.hostMember.createdAt)
        : new Date(),
      birthDate: input?.hostMember?.birthDate
        ? new Date(input.hostMember.birthDate)
        : new Date(),
      isDeleted: !!input?.hostMember?.deletedAt || true,
      usage: input?.hostMember?.usage || '',
      city: input?.hostMember?.city || '',
      maxConsumeMonth: input?.hostMember?.maxConsumeMonth || 0,
      userId: input?.hostMember?.userId || '',
      firstName: input?.hostMember?.firstName || '',
      lastName: input?.hostMember?.lastName || '',
      phoneNumber: input?.hostMember?.phoneNumber || '',
      email: input?.hostMember?.email || '',
      role: input?.hostMember?.role || UserRole.PARTNER,
      document: input?.hostMember?.document || '',
      address: input?.hostMember?.address || '',
      zipCode: input?.hostMember?.zipCode || '',
    },
    maxConsumeMonth: input.maxConsumeMonth || 0,
    // @ts-ignore
    rfidCode: input.rfidCode || '',
    // @ts-ignore
    monthConsume: input.monthConsume || 0,
    memberNum: input.memberNum || '0',
    notes: input?.notes?.map(normalizePartnerNote) || [],
    quota: {
      id: input.partnerQuota?.id || '',
      name: input.partnerQuota?.quota?.name || '',
      price: input.partnerQuota?.quota?.price || 0,
      type: input.partnerQuota?.quota?.type || QuotaType.ANNUAL,
      value: input.partnerQuota?.quota?.value || 0,
      nextPayment,
      assignedDate,
      isSubscribed,
    },
    usage: input.usage || '',
    isDeleted: false,
    lastRegister: (input?.lastRegister && new Date(input.lastRegister)) || null,
    files:
      input.files?.map((file) => ({
        id: file.id || '',
        fileName: (file.fileName || '').replaceAll('-', ' '),
        url: file.url || '',
        // @ts-ignore
        fileDriveId: file.fileDriveId || '',
      })) || [],
    supportedPartners:
      input?.supportedPartners?.map((supportedPartner) => ({
        id: supportedPartner.id || '',
        firstName: supportedPartner.user.firstName || '',
        lastName: supportedPartner.user.lastName || '',
        address: supportedPartner.user.address || '',
        email: supportedPartner.user.email || '',
        fullName: `${supportedPartner.user.firstName} ${supportedPartner.user.lastName}`,
        memberNum: supportedPartner.memberNum || '0',
        hostMemberNum: supportedPartner.hostMemberNum || '0',
        document: supportedPartner.user.document || '',
        phoneNumber: supportedPartner.user.phoneNumber || '',
        isDeleted: !!supportedPartner.deletedAt,
      })) || [],
    registers:
      input?.registers?.map((register) => ({
        id: register.id || '',
        date: new Date(register.date),
      })) || [],

    // User Fields
    ...normalizeBaseUser(input.user),
  };
};

export const normalizePartnerToList = (
  input: PartnerListData | PartnerListDataFragment,
) => {
  const nextPayment = input.partnerQuota?.nextPayment
    ? format(parseISO(input.partnerQuota.nextPayment), 'dd-MM-yyyy')
    : '';

  let isSubscribed = false;
  if (nextPayment) {
    isSubscribed = !isBefore(new Date(nextPayment), new Date());
  }

  return {
    // Primary key
    id: input.id || '',

    // Partner fields
    credits: input.credits ? Number(input.credits.toFixed(2)) : 0,
    hostMemberNum: input?.hostMemberNum || '0',
    memberNum: input.memberNum || '0',
    quota: {
      nextPayment,
      isSubscribed,
    },
    discounts:
      'discounts' in input && input?.discounts && input?.discounts?.length > 0
        ? input.discounts.map((discount) => ({
            id: discount.id || '',
            name: discount.name || '',
            type: discount.type
              ? (discount.type as unknown as DiscountType)
              : DiscountType.Product,
            price: discount.price || 0,
            percentage: discount.percentage || 0,
          }))
        : [],

    // User Fields
    document: input.user?.document || '',
    firstName: input.user?.firstName || '',
    fullName: `${input.user?.firstName} ${input.user?.lastName}`,
    lastName: input.user?.lastName || '',
    email: input.user?.email || '',
    // @ts-ignore
    avatarImageUrl: input.user?.avatar?.url || '',
  };
};

export type PartnerToList = ReturnType<typeof normalizePartnerToList>;
export type Partner = ReturnType<typeof normalizePartner>;
