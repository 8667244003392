import { gql } from '@apollo/client';
import { IMAGE_FRAGMENT } from './Image';

export const PRODUCT_FRAGMENT = gql`
  fragment ProductData on Product {
    id
    baseWeight
    categories {
      ... on Category {
        id
        name
        type
        description
      }
    }
    countToMaxConsume
    createdAt
    deletedAt
    description
    isActive
    showInMenu
    name
    costs
    price
    quantity
    storeQuantity
    taxes
    referenceCode
    barCode
    updatedAt
    discount {
      ... on Discount {
        id
        type
        name
        percentage
        price
      }
    }
    mainImage {
      ...ImageData
    }
  }
  ${IMAGE_FRAGMENT}
`;

export const PRODUCT_TRANSACTIONS_FRAGMENT = gql`
  fragment ProductTransactionsData on ProductTransactions {
    product {
      ... on Product {
        id
        name
        referenceCode
      }
    }
    transactions {
      purchaseId
      partner {
        ... on Partner {
          id
          memberNum
          user {
            ... on User {
              firstName
              lastName
            }
          }
        }
      }
      employee {
        ... on Employee {
          id
          user {
            ... on User {
              firstName
              lastName
            }
          }
        }
      }
      total
      quantity
      extraQuantity
      date
    }
  }
`;

export const PRODUCT_QUANTITY_HISTORY_FRAGMENT = gql`
  fragment ProductQuantityHistoryData on ProductQuantityHistory {
    id
    createdAt
    currentBaseWeight
    currentQuantity
    lastBaseWeight
    lastQuantity
    note
    movementType
    lastStock
    currentStock
    editedBy {
      ... on Employee {
        id
        user {
          ... on User {
            firstName
            lastName
          }
        }
      }
    }
    product {
      ... on Product {
        name
        storeQuantity
        mainImage {
          ...ImageData
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
`;

export const PRODUCT_STATISTICS_FRAGMENT = gql`
  fragment ProductStatisticsData on ProductStatistics {
    id
    countToMaxConsume
    consumedExtraQuantity
    consumedQuantity
    totalCosts
    totalPurchases
    totalOrders
    referenceCode
  }
`;
