import { FC, memo } from 'react';
import { useMediaQuery } from '@mui/material';
import { from } from 'styles/responsive';
import { QuantityMovementType } from 'apollo/graphql.types';
import Price from 'components/Price';
import { formatDate } from 'utils/dates/formatDates';
import {
  Container,
  Credits,
  Date,
  Employee,
  LastQuantity,
  MovementType,
  NewQuantity,
  Note,
  NoteEmployee,
} from './styles';
import type { Props } from './types';

const Row: FC<Props> = ({ adjustHistory, className, onClick }) => {
  const fromTabletPortrait = useMediaQuery(from.tabletPortrait.query);
  const employeeFullName = `${adjustHistory.editedBy.firstName} ${adjustHistory.editedBy.lastName}`;

  const isStoreMovement =
    adjustHistory.movementType === QuantityMovementType.FromStore;
  const isBaseWeightMovement =
    adjustHistory.movementType === QuantityMovementType.BaseWeight;

  let movementText = 'Stock Directo';
  let lastQuantity = adjustHistory.lastQuantity || 0;
  let currentQuantity = adjustHistory.currentQuantity || 0;
  let adjustDifference = adjustHistory.adjustDifference || 0;

  if (isStoreMovement) {
    movementText = 'Almacén';
    lastQuantity = adjustHistory.lastStock || 0;
    currentQuantity = adjustHistory.currentStock || 0;
    adjustDifference = adjustHistory.adjustDifference * -1;
  }

  if (isBaseWeightMovement) {
    movementText = 'Peso Base';
    lastQuantity = adjustHistory.lastBaseWeight;
    currentQuantity = adjustHistory.currentBaseWeight;
    adjustDifference = 0.0;
  }

  return (
    <Container className={className} onClick={() => onClick(adjustHistory)}>
      <NoteEmployee>
        <Employee> {employeeFullName}</Employee>
      </NoteEmployee>
      <Date>{formatDate(adjustHistory.createdAt)}</Date>
      {fromTabletPortrait && (
        <>
          <LastQuantity>{lastQuantity.toFixed(2)}</LastQuantity>
          <NewQuantity>{currentQuantity.toFixed(2)}</NewQuantity>
          <MovementType>{movementText}</MovementType>
          <Note>{adjustHistory.note}</Note>
        </>
      )}

      <Credits $isNegative={adjustDifference < 0}>
        <Price showIcon={false}>{Number(adjustDifference.toFixed(2))}</Price>
      </Credits>
    </Container>
  );
};

export default memo(Row);
