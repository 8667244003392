import type { FC } from 'react';
import Loader from 'components/Loader';
import DateTimeFilter from 'components/DateTimeFilter';
import Price from 'components/Price';
import Row from './Row';
import TableHead from './TableHead';
import useConnect from './connect';
import {
  Container,
  EmptyState,
  Main,
  DateTimeFilterContainer,
  Square,
  Squares,
  SquareTitle,
  SquareValue,
} from './styles';

const ProductTransactions: FC = () => {
  const {
    transactions,
    productStatistics,
    filterDates,
    handleOnSearch,
    isLoading,
  } = useConnect();

  const hasResults = transactions?.length > 0;

  return (
    <Container>
      <DateTimeFilterContainer>
        <DateTimeFilter
          fromDate={filterDates.from}
          toDate={filterDates.to}
          onSearch={handleOnSearch}
        />
      </DateTimeFilterContainer>
      {isLoading && <Loader />}
      {!isLoading && (
        <Main>
          <Squares>
            <Square>
              <SquareTitle>Ganancias</SquareTitle>
              <SquareValue>
                <Price>{productStatistics.totalPurchases}</Price>
              </SquareValue>
            </Square>
            <Square>
              <SquareTitle>Costes</SquareTitle>
              <SquareValue>
                <Price>{productStatistics.totalCosts}</Price>
              </SquareValue>
            </Square>
            <Square>
              <SquareTitle>Unidades</SquareTitle>
              <SquareValue>{productStatistics.consumedQuantity}</SquareValue>
            </Square>
            {productStatistics.countToMaxConsume && (
              <Square>
                <SquareTitle>Unidades Reales</SquareTitle>
                <SquareValue>
                  {productStatistics.consumedExtraQuantity}
                </SquareValue>
              </Square>
            )}
            <Square>
              <SquareTitle>N° Ventas</SquareTitle>
              <SquareValue>{productStatistics.totalOrders}</SquareValue>
            </Square>
          </Squares>
          {!hasResults && (
            <EmptyState
              title="No se puede generar la información"
              iconName="products"
            >
              No hay datos suficientes para generar estadísticas de este
              producto
            </EmptyState>
          )}
          {hasResults && (
            <>
              <TableHead
                countToMaxConsume={productStatistics.countToMaxConsume}
              />
              {transactions.map((transaction) => (
                <Row
                  key={transaction.id}
                  transaction={transaction}
                  countToMaxConsume={productStatistics.countToMaxConsume}
                />
              ))}
            </>
          )}
        </Main>
      )}
    </Container>
  );
};

export default ProductTransactions;
