import { FC, memo } from 'react';

import type { Props } from './types';

const Weight: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={size}
    height={size}
    {...rest}
  >
    <path
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      d="M38 8h-2.9A6.2 6.2 0 0 0 34 6a6 6 0 0 0-4.5-2H18.54A6 6 0 0 0 14 6a6.2 6.2 0 0 0-1.14 2H10a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h28a6 6 0 0 0 6-6V14a6 6 0 0 0-6-6m-20.96.68a2 2 0 0 1 1.5-.68h10.92a2 2 0 0 1 1.5.68 2 2 0 0 1 .5 1.56l-1 8a2 2 0 0 1-2 1.76h-3.28l2.28-4.8a2 2 0 0 0-3.6-1.72L20.74 20h-1.2a2 2 0 0 1-2-1.76l-1-8a2 2 0 0 1 .5-1.56M40 38a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V14a2 2 0 0 1 2-2h2.74l.84 6.74a6 6 0 0 0 6 5.26h8.92a6 6 0 0 0 6-5.26l.76-6.74H38a2 2 0 0 1 2 2zm-12-6h-8a2 2 0 0 0 0 4h8a2 2 0 0 0 0-4"
    />
  </svg>
);

export default memo(Weight);
