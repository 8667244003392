import { useCallback, useEffect, useMemo, useState } from 'react';
import html2pdf from 'html2pdf.js';
import { isAfter, subHours } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import {
  orderVar,
  subscriptionStateVar,
  permissionsStateVar,
} from 'apollo/reactive';
import useModal from 'apollo/hooks/useModal';
import usePartner from 'apollo/hooks/partner/usePartner';
import useMe from 'apollo/hooks/user/useMe';
import usePartnerActions from 'apollo/hooks/partner/usePartnerActions';
import useEmployeeActions from 'apollo/hooks/employee/useEmployeeActions';
import useDiscounts from 'apollo/hooks/discount/useDiscounts';
import usePartnerConsumptionsStats from 'apollo/hooks/partner/usePartnerConsumptionsStats';
import useUploadFile from 'hooks/useUploadFile';
import { formatErrors } from 'utils/errors/formatErrors';
import { replaceDocumentData } from 'utils/texts/document';
import NotifySnackbarErrorButton from 'components/NotifySnackbarErrorButton';
import { PartnerUsage, UserGender } from 'apollo/generated/globalTypes';
import { DiscountType } from 'apollo/graphql.types';
import type { Partner, PartnerFormFields } from 'model/Partner';
import type { Discount } from 'model/Discount';
import type { OrganizationDocument } from 'model/Organization';

const useConnect = () => {
  const { partnerId } = useParams<{ partnerId: string }>();
  const {
    partner,
    loading: getPartnerLoading,
    refetchPartner,
  } = usePartner({
    id: partnerId,
    fetchPolicy: 'cache-and-network',
  });
  const { consumptionsStats, loading: getPartnerStatsLoading } =
    usePartnerConsumptionsStats(partnerId);
  const { data: me, loading: getMeLoading } = useMe({
    fetchPolicy: 'cache-first',
  });
  const subscription = useReactiveVar(subscriptionStateVar);
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const {
    assignPartnerDiscounts,
    deletePartner,
    updatePartner,
    updatePartnerAvatar,
    registerPartnerPresence,
    loading: partnerActionsLoading,
  } = usePartnerActions();
  const { refetch: refetchDiscounts } = useDiscounts();
  const { convertPartnerToEmployee } = useEmployeeActions();
  const { uploadFile, isUploadFileLoading } = useUploadFile(partnerId || '');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    close: closeModal,
    openManageCredits,
    openAssignDiscounts,
    openAssignQuota,
    openDialog,
    openExpiredQuotaDialog,
    openNewOrder,
    openPartnerAdvancedMenu,
    openPartnerDetails,
    openPartnerNote,
    openQuotaDetails,
    openGenerateFileDialog,
    openDocuments,
    openSignDocument,
    openSetDocumentData,
    openGenerateBarCodeDialog,
  } = useModal();
  const [selectedSection, setSelectedSection] = useState('profile');
  const organization = me?.organization;
  const hasPartnerPresenceRegistered =
    partner?.lastRegister &&
    isAfter(partner.lastRegister, subHours(new Date(), 4));

  useEffect(() => {
    const activeNote = partner?.notes?.find((note) => note.showNotification);
    if (activeNote && partner?.id) {
      openPartnerNote({ note: activeNote, partnerId: partner.id });
    } else if (
      !partner?.quota.isSubscribed &&
      partner?.id &&
      employeePermissions.allowAssignQuotas
    ) {
      openExpiredQuotaDialog({
        partner,
        onRefetchPartner: async () => {
          await refetchPartner();
        },
      });
    }
  }, [
    employeePermissions,
    refetchPartner,
    partner,
    openExpiredQuotaDialog,
    openPartnerNote,
  ]);

  const initialProfileValues = useMemo<PartnerFormFields>(
    () => ({
      id: partner?.id || '',
      address: partner?.address || '',
      birthDate: partner?.birthDate || null,
      credits: partner?.credits || 0,
      document: partner?.document || '',
      email: partner?.email || '',
      firstName: partner?.firstName || '',
      gender: partner?.gender || UserGender.OTHER,
      hostMemberNum: partner?.hostMemberNum || '',
      lastName: partner?.lastName || '',
      maxConsumeMonth: partner?.maxConsumeMonth || 0,
      memberNum: partner?.memberNum || '',
      phoneNumber: partner?.phoneNumber || '',
      rfidCode: partner?.rfidCode || '',
      usage: partner?.usage || PartnerUsage.PLAYFUL,
      // presenceOrganizationFrom: partner?.presenceOrganizationFrom || null,

      // Images
      avatarImageUrl: partner?.avatarImageUrl || '',

      // Read Only
      quotaName: partner?.quota.name || 'Asignar cuota',
      monthConsume: `${(partner?.monthConsume || 0).toFixed(2)} de ${
        partner?.maxConsumeMonth || 0
      }`,
    }),
    [partner],
  );

  const handleSubmitProfile = useCallback(
    async (values: PartnerFormFields) => {
      try {
        if (partnerId) {
          await updatePartner({
            id: partnerId,
            data: values,
          });
          enqueueSnackbar('El socio se ha actualizado correctamente', {
            variant: 'success',
          });
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('partner', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [enqueueSnackbar, partnerId, updatePartner],
  );

  const handleSubmitAvatar = useCallback(
    async (imageId: string) => {
      try {
        if (partnerId) {
          await updatePartnerAvatar({
            id: partnerId,
            imageId,
          });
          enqueueSnackbar('Se ha actualizado la imagen correctamente', {
            variant: 'success',
          });
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('partner', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [enqueueSnackbar, partnerId, updatePartnerAvatar],
  );

  const handleRemovePartner = useCallback(async () => {
    try {
      if (partnerId) {
        await deletePartner(partnerId);
        navigate('/partners');
        closeModal();
        enqueueSnackbar('El socio se ha eliminado correctamente', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(formatErrors('partner', e.message, 'eliminar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [closeModal, deletePartner, enqueueSnackbar, navigate, partnerId]);

  const handleRegisterPartnerPresence = useCallback(async () => {
    try {
      if (partnerId && !hasPartnerPresenceRegistered) {
        await registerPartnerPresence(partnerId);
        enqueueSnackbar('El socio se ha registrado correctamente', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(formatErrors('partner', e.message, 'actualizar'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [
    enqueueSnackbar,
    hasPartnerPresenceRegistered,
    partnerId,
    registerPartnerPresence,
  ]);

  const handleConvertPartnerToEmployee = useCallback(async () => {
    try {
      if (partnerId) {
        const employee = await convertPartnerToEmployee(partnerId);
        if (employee) {
          navigate(`/employees/${employee.id}`);
        }
        closeModal();
        enqueueSnackbar('El empleado se ha creado correctamente', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(formatErrors('employee', e.message, 'crear'), {
        variant: 'error',
        action: () => <NotifySnackbarErrorButton error={e} />,
      });
    }
  }, [
    closeModal,
    convertPartnerToEmployee,
    enqueueSnackbar,
    navigate,
    partnerId,
  ]);

  const handleOpenRemoveDialog = useCallback(() => {
    openDialog({
      acceptButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      description:
        'Vas a eliminar a este socio, se borrarán todos sus datos y es una acción que no se puede deshacer, ¿quieres eliminarlo?',
      onAccept: handleRemovePartner,
      title: 'Eliminar usuario',
      variant: 'danger',
      iconName: 'users',
    });
  }, [openDialog, handleRemovePartner]);

  const handleOpenConvertToPartnerToEmployeeDialog = useCallback(() => {
    openDialog({
      acceptButtonText: 'Convertir',
      cancelButtonText: 'Cancelar',
      description: 'Vas a convertir este socio en empleado ¿estás seguro?',
      onAccept: handleConvertPartnerToEmployee,
      title: 'Convertir socio en empleado',
      variant: 'default',
      iconName: 'employees',
    });
  }, [openDialog, handleConvertPartnerToEmployee]);

  const handleAssignDiscounts = useCallback(
    async (discounts: Discount[]) => {
      try {
        if (partnerId) {
          await assignPartnerDiscounts({
            id: partnerId,
            discountsIds: discounts.map(({ id }) => id),
          });
          await refetchDiscounts();
          enqueueSnackbar('Se han asignado los descuento correctamente', {
            variant: 'success',
          });
        }
      } catch (e) {
        enqueueSnackbar(formatErrors('partner', e.message, 'actualizar'), {
          variant: 'error',
          action: () => <NotifySnackbarErrorButton error={e} />,
        });
      }
    },
    [assignPartnerDiscounts, enqueueSnackbar, partnerId, refetchDiscounts],
  );

  const handleOpenDiscounts = useCallback(
    (partner: Partner) => {
      openAssignDiscounts({
        partnerId: partner.id,
        onSubmit: handleAssignDiscounts,
      });
    },
    [handleAssignDiscounts, openAssignDiscounts],
  );

  const handleSelectedSection = useCallback((section: string) => {
    setSelectedSection(section);
  }, []);

  const handleOpenAddCreditsModal = useCallback(
    (action: 'added' | 'retire') => {
      if (partner) {
        openManageCredits({
          action,
          partner,
          onReturn: async () => {
            await refetchPartner();
          },
        });
      }
    },
    [openManageCredits, partner, refetchPartner],
  );

  const handleNewOrder = useCallback(
    async (partner: Partner) => {
      if (partner) {
        const orderDiscounts = partner.discounts.filter(
          (discount) => discount.type === DiscountType.Product,
        );
        const productDiscounts = partner.discounts.filter(
          (discount) => discount.type === DiscountType.UnitProduct,
        );

        const productDiscount = productDiscounts?.[0] || null;
        const orderDiscount = orderDiscounts?.[0] || null;

        orderVar({
          products: [],
          partner,
          orderDiscount: subscription.allowedDiscounts ? orderDiscount : null,
          productDiscount: subscription.allowedDiscounts
            ? productDiscount
            : null,
        });
        openNewOrder({
          onSubmitPurchase: async () => {
            await refetchPartner();
          },
        });
      }
    },
    [openNewOrder, refetchPartner, subscription],
  );

  const handleOpenHostPartnerDetailsModal = useCallback(async () => {
    if (partner?.hostMember) {
      openPartnerDetails({ partner: partner.hostMember as unknown as Partner });
    }
  }, [openPartnerDetails, partner]);

  const handleDownloadPDF = useCallback(
    async ({
      customData,
      document,
      sign,
      hostSign,
      type,
    }: {
      document: OrganizationDocument;
      sign?: string;
      hostSign?: string;
      customData?: Record<string, string | number>;
      type: 'download' | 'upload';
    }) => {
      if (partner && organization && document.text) {
        const fileName = `${document.name}_socio_${partner.memberNum}__${partner.fullName}`;
        const formattedText = replaceDocumentData({
          customData,
          partner,
          organization,
          text: document.text,
          signatureData: sign,
          hostSignatureData: hostSign,
        });

        const pdfObj = html2pdf()
          .from(formattedText.trim())
          .set({
            margin: [15, 10],
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            html2canvas: {
              scale: 2,
              logging: true,
              dpi: 192,
              letterRendering: true,
            },
          })
          .toPdf()
          .get('pdf')
          .then((pdf: any) => {
            const totalPages = pdf.internal.getNumberOfPages();
            pdf.setFontSize(12);
            pdf.setTextColor(100);

            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i);

              const pageWidth = pdf.internal.pageSize.getWidth();
              const text = `${i}/${totalPages}`;
              const textWidth =
                (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
                pdf.internal.scaleFactor;
              const xPosition = pageWidth - textWidth - 10;

              pdf.text(text, xPosition, pdf.internal.pageSize.getHeight() - 5);
            }
          });

        if (type === 'download') {
          pdfObj.save(fileName);
        } else if (type === 'upload') {
          const pdf = await pdfObj.outputPdf('blob');
          await uploadFile({ file: pdf, fileName });
        }
        closeModal();
      }
    },
    [partner, organization, closeModal, uploadFile],
  );

  const handleOpenGeneratedFile = useCallback(
    ({
      document,
      sign,
      hostSign,
      customData,
    }: {
      document: OrganizationDocument;
      sign?: string;
      hostSign?: string;
      customData?: Record<string, string | number>;
    }) => {
      openGenerateFileDialog({
        onDownload: () =>
          handleDownloadPDF({
            document,
            sign,
            hostSign,
            customData,
            type: 'download',
          }),
        onUpload: () =>
          handleDownloadPDF({
            document,
            sign,
            hostSign,
            customData,
            type: 'upload',
          }),
        title: `Documento ${document.name}`,
        description: `Se va a generar el documento ${document.name}`,
      });
    },
    [handleDownloadPDF, openGenerateFileDialog],
  );

  const handleValidateFile = useCallback(
    async (document: OrganizationDocument) => {
      if (document.hasSignature) {
        const signsCount = new Set(document.text.match(/{(firma_[a-zA-Z]+)}/g));
        openSignDocument({
          hasHostSign: signsCount.size > 1,
          onClick: (sign: string, hostSign: string) =>
            document.hasCustomData
              ? openSetDocumentData({
                  customData: document.customData,
                  onSubmit: (values) =>
                    handleOpenGeneratedFile({
                      document,
                      sign,
                      hostSign,
                      customData: values,
                    }),
                })
              : handleOpenGeneratedFile({ document, sign, hostSign }),
        });
      } else if (document.hasCustomData) {
        openSetDocumentData({
          customData: document.customData,
          onSubmit: (values) =>
            handleOpenGeneratedFile({ document, customData: values }),
        });
      } else {
        handleOpenGeneratedFile({ document });
      }
    },
    [openSetDocumentData, handleOpenGeneratedFile, openSignDocument],
  );

  const handleOpenNewOrderModal = useCallback(async () => {
    if (partner) {
      await handleNewOrder(partner);
    }
  }, [handleNewOrder, partner]);

  const handleOpenBarCodeModal = useCallback(() => {
    if (partner?.id) {
      openGenerateBarCodeDialog({ value: partner.memberNum });
    }
  }, [openGenerateBarCodeDialog, partner]);

  const handleOpenAdvancedPartnerMenuModal = useCallback(async () => {
    if (partner) {
      openPartnerAdvancedMenu({
        removePartner: handleOpenRemoveDialog,
        loadCredits: handleOpenAddCreditsModal,
        isEmployee: partner.isEmployee,
        generateContractFile: () =>
          openDocuments({
            onSelect: handleValidateFile,
          }),
        assignDiscounts: () => handleOpenDiscounts(partner),
        convertToPartnerToEmployee: handleOpenConvertToPartnerToEmployeeDialog,
        openOrder: handleOpenNewOrderModal,
        showBarCode: handleOpenBarCodeModal,
      });
    }
  }, [
    partner,
    openPartnerAdvancedMenu,
    handleOpenRemoveDialog,
    handleOpenAddCreditsModal,
    handleOpenConvertToPartnerToEmployeeDialog,
    openDocuments,
    handleValidateFile,
    handleOpenDiscounts,
    handleOpenNewOrderModal,
    handleOpenBarCodeModal,
  ]);

  const handleOpenQuotaDetailModal = useCallback(async () => {
    if (partner) {
      if (partner.quota?.id) {
        openQuotaDetails({
          quota: partner.quota,
          partnerId: partner.id,
          onRefetch: async () => {
            await refetchPartner();
          },
        });
      } else if (!partner.quota?.id && employeePermissions.allowAssignQuotas) {
        openAssignQuota({
          partner,
          onRefetchPartner: async () => {
            await refetchPartner();
          },
        });
      }
    }
  }, [
    employeePermissions,
    refetchPartner,
    openAssignQuota,
    openQuotaDetails,
    partner,
  ]);

  return {
    handleOpenAddCreditsModal,
    handleOpenAdvancedPartnerMenuModal,
    handleOpenHostPartnerDetailsModal,
    handleOpenNewOrderModal,
    handleOpenQuotaDetailModal,
    handleOpenRemoveDialog,
    handleRegisterPartnerPresence,
    handleSelectedSection,
    handleSubmitAvatar,
    handleSubmitProfile,
    hasPartnerPresenceRegistered,
    initialProfileValues,
    isLoading:
      getPartnerLoading ||
      partnerActionsLoading ||
      getMeLoading ||
      getPartnerStatsLoading,
    fileActionsLoading: isUploadFileLoading,
    partner,
    selectedSection,
    subscription,
    consumptionsStats,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
