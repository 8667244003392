import { FC, memo } from 'react';
import { applyDiscount } from 'utils/discounts';
import {
  Container,
  InfoProduct,
  Label,
  Price,
  Thumbnail,
  Image,
  ImageContainer,
  ReferenceCode,
  PriceContent,
  OriginalPrice,
  ProductQuantity,
} from './styles';
import type { Props } from './types';

const LandscapeFormat: FC<Props> = ({
  activateProductReference,
  onSelect,
  product,
}) => {
  const { abbreviation, name, price, discount } = product;

  const originalPrice = price;
  const finalPrice = !discount
    ? originalPrice
    : applyDiscount({
        discount,
        price: Number(originalPrice),
      });

  return (
    <Container onClick={onSelect}>
      {!product.mainImageUrl ? (
        <Thumbnail>{abbreviation}</Thumbnail>
      ) : (
        <ImageContainer>
          <Image src={product.mainImageUrl} />
        </ImageContainer>
      )}

      <InfoProduct>
        <Label>
          {name}{' '}
          <ProductQuantity>({product.quantity.toFixed(2)})</ProductQuantity>
        </Label>
        {activateProductReference && product.referenceCode && (
          <ReferenceCode>#{product.referenceCode}</ReferenceCode>
        )}
        <PriceContent>
          {discount && (
            <OriginalPrice>
              <Price>{originalPrice}</Price>
            </OriginalPrice>
          )}
          <Price>{finalPrice}</Price>
        </PriceContent>
      </InfoProduct>
    </Container>
  );
};

export default memo(LandscapeFormat);
