import { gql } from '@apollo/client';
import {
  CASHIER_BY_PERIOD_FRAGMENT,
  CASHIER_CATEGORY_BY_PERIOD_FRAGMENT,
  CASHIER_FRAGMENT,
  CASHIER_PARTNERS_BY_PERIOD_FRAGMENT,
  CASHIER_PRODUCTS_BY_PERIOD_FRAGMENT,
} from 'apollo/fragments/Cashier';

export const GET_CASHIER_BY_PERIOD = gql`
  query CashierByPeriod($filters: CashierFilters) {
    cashierByPeriod(filters: $filters) {
      ...CashierByPeriodData
    }
  }
  ${CASHIER_BY_PERIOD_FRAGMENT}
`;

export const GET_CASHIER_PRODUCTS = gql`
  query getCashierProducts($filters: CashierProductsFilters) {
    cashierProducts(filters: $filters) {
      ...CashierProductsByPeriodData
    }
  }
  ${CASHIER_PRODUCTS_BY_PERIOD_FRAGMENT}
`;

export const GET_CASHIER_CATEGORIES = gql`
  query getCashierCategories($filters: CashierCategoriesFilters) {
    cashierCategories(filters: $filters) {
      ...CashierCategoryByPeriodData
    }
  }
  ${CASHIER_CATEGORY_BY_PERIOD_FRAGMENT}
`;

export const GET_CASHIER_PARTNERS = gql`
  query getCashierPartners($filters: CashierPartnersFilters) {
    cashierPartners(filters: $filters) {
      ...CashierPartnersByPeriodData
    }
  }
  ${CASHIER_PARTNERS_BY_PERIOD_FRAGMENT}
`;

export const GET_CASHIERS = gql`
  query Cashiers($filters: CashierFilters) {
    cashiers(filters: $filters) {
      ...CashierData
    }
  }
  ${CASHIER_FRAGMENT}
`;

export const GET_LAST_CASHIER = gql`
  query GetLastCashier {
    getLastCashier {
      ...CashierData
    }
  }
  ${CASHIER_FRAGMENT}
`;
